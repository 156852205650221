/* eslint-disable */
import React, { SyntheticEvent, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

// MUI Icons
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Openlayers

// Custom Components
import MapCard from '@/ui/MapCard/MapCard';
import MapCardHeader from '@/ui/MapCard/MapCardHeader';
import MapCardAvatar from '@/ui/MapCard/MapCardAvatar';
import MapCardContent from '@/ui/MapCard/MapCardContent';
import MapCardActions from '@/ui/MapCard/MapCardActions';
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import MapCardHeaderButtonClose from '@/ui/MapCard/MapCardHeaderButtonClose';
import MapCardHeaderButtonLayers from '@/ui/MapCard/MapCardHeaderButtonLayers';
import { GridContainer, GridItem } from '@/ui/Grid';
import PPVKupoprodajniUgovoriDialog from '@/views/PPV/PPVKupoprodajniUgovoriDialog';

// Lib
import DataController from '@/lib/DataController';

// Models
import modelKupoprodajniUgovori from '@/models/kupoprodajni_ugovori';

// Types
import { IGFICardOverlayData } from '@/@types/views/map';
import { DCRecord } from '@/@types/lib/dataController';
import DialogContext from '@/context/DialogContext/DialogContext';

export interface PPVInfoCardProps {
  featureData: IGFICardOverlayData;
  onClose: () => void;
  setIndex: React.Dispatch<React.SetStateAction<any>>;
  currentIndex: any;
  handlePopoverOverlayClick: any;
  layerPickerDisabled?: boolean;
}

const PPVInfoCard = (props: PPVInfoCardProps) => {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const {
    featureData,
    onClose,
    setIndex,
    currentIndex,
    layerPickerDisabled,
    handlePopoverOverlayClick
  } = props;

  const initialRecord = featureData.record
    ? featureData.record
    : featureData.features[0].properties;

  const [record, setRecord] = useState(initialRecord);

  const [page, setPage] = useState<number>(1);

  const { layerData } = featureData;

  const { layer, model, layerFunctionalityId } = layerData;

  const [recordsUgovori, setRecordsUgovori] = useState<DCRecord[]>([]);
  const dcUgovori = new DataController(modelKupoprodajniUgovori);

  const faIconClass = 'fas fa-info ';

  useEffect(() => {
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.features[currentIndex[layer]]) {
        setRecord(featureData.features[currentIndex[layer]].properties);
      } else if (featureData.features[0]) {
        setIndex((prev: any) => {
          const obj = { ...prev };
          obj[layer] = 0;
          return obj;
        });
        setRecord(featureData.features[0].properties);
      }
    }
  }, [featureData, currentIndex]);

  useEffect(() => {
    if (currentIndex && Object.keys(currentIndex).length > 0) {
      setPage(currentIndex[layer] + 1);
    }
  }, [layer]);

  const loadUgovori = () => {
    if (record) {
      dcUgovori
        .GetData(`data/cjenovni-blokovi/${record.id}/kupoprodajni-ugovori`)
        .then((resp) => {
          if (resp.success && Array.isArray(resp.data)) {
            setRecordsUgovori(resp.data);
          }
        });
    }
  };

  useEffect(() => {
    if (record.id) {
      loadUgovori();
    }
  }, [record]);

  const handleUgovoriClick = (evt: SyntheticEvent) => {
    dialogContext.showDialog(PPVKupoprodajniUgovoriDialog, {
      cjenovniBlokRecord: record,
      recordsUgovori: recordsUgovori,
      dcUgovori: dcUgovori
    });

  }

  const fields = model?.fields || [];
  const fieldNames = fields.map((field) => field.source);

  const modelTitle =
    model?.title_type === 1
      ? t(`${model.title_token}`)
      : model?.title_type === 2 && model?.title_field
        ? record[model.title_field]
        : model?.title_type === 3 && model?.title_field
          ? `${t(`${model.title_token}`)}: ${record[model.title_field]}`
          : null;

  const title = model
    ? modelTitle
    : record.naziv
      ? record.naziv
      : layer
        ? t(`map:layer.${layer}`)
        : 'Info';

  return (
    <MapCard
      sx={{
        backgroundColor: 'background.default',
        minWidth: '600px',
        resize: "horizontal"
      }}
    >
      <MapCardHeader
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Stack justifyContent="center" color="white"
            sx={{
              maxWidth: '75%', // Adjust the maxWidth as needed
            }}
            >
              <Typography variant="body1">{title}</Typography>
              {record.id && record.id !== null ? (
                <Typography variant="body2">{`${t('common.id')}: ${record.id
                  }`}</Typography>
              ) : null}
            </Stack>
            <Stack alignItems="center">
              <Pagination
                hidden={featureData.features.length <= 1}
                size="small"
                shape="rounded"
                count={featureData.features.length}
                defaultPage={1}
                siblingCount={0}
                boundaryCount={0}
                sx={{
                  mt: 1,
                }}
                page={page}
                onChange={(event, page) => {
                  setPage(page);
                  setIndex((prev: any) => {
                    const obj = { ...prev };
                    obj[layer] = page - 1;
                    return obj;
                  });
                }}
              />
            </Stack>
          </div>
        }
        disableTypography
        avatar={
          <MapCardAvatar
            ariaLabel="info"
            icon={<i className={faIconClass} />}
          />
        }
        action={
          <Stack m={0} direction="row">
            {!layerPickerDisabled && (
              <MapCardHeaderButtonLayers onClick={handlePopoverOverlayClick} />
            )}
            <MapCardHeaderButtonClose onClick={onClose} />
          </Stack>
        }
      />
      <MapCardContent>
        <GridContainer spacing={0}>
          <GridItem xs={12} container>
            <GridContainer spacing={0}>
              <TableContainer component={Box}>
                <Table sx={{}} size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('cjenovni_blokovi.razred')}</TableCell>
                      <TableCell align="right">
                        {t('cjenovni_blokovi.cijena_po_povrsini')}
                      </TableCell>
                      <TableCell align="right">
                        {t('cjenovni_blokovi.status')}
                      </TableCell>
                      <TableCell align="right">
                        {t('cjenovni_blokovi.broj_uzoraka')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {record ? (
                      <>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t('cjenovni_blokovi.do_20')}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_do_20 ? record.p_do_20 + ' €/m²' : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.status_do_20 ? (
                              <>
                                {record.status_do_20 + ' '}
                                <Tooltip
                                  title={t(
                                    'cjenovni_blokovi.statusi.' +
                                    record.status_do_20
                                  )}
                                >
                                  <span>🛈</span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_do_20 ? record.n_do_20 : null}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t('cjenovni_blokovi.20_35')}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_20_35 ? record.p_20_35 + ' €/m²' : null}
                          </TableCell>

                          <TableCell align="right">
                            {record.status_20_35 ? (
                              <>
                                {record.status_20_35 + ' '}
                                <Tooltip
                                  title={t(
                                    'cjenovni_blokovi.statusi.' +
                                    record.status_20_35
                                  )}
                                >
                                  <span>🛈</span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>

                          <TableCell align="right">
                            {record.p_20_35 ? record.n_20_35 : null}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t('cjenovni_blokovi.35_55')}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_35_55 ? record.p_35_55 + ' €/m²' : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.status_35_55 ? (
                              <>
                                {record.status_35_55 + ' '}
                                <Tooltip
                                  title={t(
                                    'cjenovni_blokovi.statusi.' +
                                    record.status_35_55
                                  )}
                                >
                                  <span>🛈</span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_35_55 ? record.n_35_55 : null}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t('cjenovni_blokovi.55_75')}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_55_75 ? record.p_55_75 + ' €/m²' : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.status_55_75 ? (
                              <>
                                {record.status_55_75 + ' '}
                                <Tooltip
                                  title={t(
                                    'cjenovni_blokovi.statusi.' +
                                    record.status_55_75
                                  )}
                                >
                                  <span>🛈</span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_55_75 ? record.n_55_75 : null}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t('cjenovni_blokovi.75_100')}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_75_100 ? record.p_75_100 + ' €/m²' : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.status_75_100 ? (
                              <>
                                {record.status_75_100 + ' '}
                                <Tooltip
                                  title={t(
                                    'cjenovni_blokovi.statusi.' +
                                    record.status_75_100
                                  )}
                                >
                                  <span>🛈</span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_75_100 ? record.n_75_100 : null}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {t('cjenovni_blokovi.od_100')}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_od_100 ? record.p_od_100 + ' €/m²' : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.status_od_100 ? (
                              <>
                                {record.status_od_100 + ' '}
                                <Tooltip
                                  title={t(
                                    'cjenovni_blokovi.statusi.' +
                                    record.status_od_100
                                  )}
                                >
                                  <span>🛈</span>
                                </Tooltip>
                              </>
                            ) : null}
                          </TableCell>
                          <TableCell align="right">
                            {record.p_od_100 ? record.n_od_100 : null}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </MapCardContent>
      <MapCardActions>
        <MapCardActionButton
          startIcon={<AssignmentIndIcon />}
          endIcon={<OpenInNewIcon />}
          onClick={handleUgovoriClick}
          disabled={recordsUgovori.length === 0}
        >
          {t('cjenovni_blokovi.ugovori')}
        </MapCardActionButton>
      </MapCardActions>
    </MapCard>
  );
};

export default PPVInfoCard;
