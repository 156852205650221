/* eslint-disable */
import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';

//MUI
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//OL
import OlBaseLayer from 'ol/layer/Base';
import OlLayerGroup from 'ol/layer/Group';
import OlView from 'ol/View';

//Custom components
import LayerTreeItem from './LayerTreeItem';
import MapContext from '@/context/MapContext/MapContext';
import { findLayer, flattenLayers } from '@/lib/olHelpers';

//Types
import { MapContextType } from '@/context/MapContext/MapContext';
import { ILayerTreeProps } from '@/@types/components/Map/Controls/Custom/LayerTree';
//import { isBigInt64Array } from 'util/types';
import { EventsKey } from 'ol/events';
import BaseEvent from 'ol/events/Event';
import { IconButton } from '@mui/material';

const LayerTree: FunctionComponent<ILayerTreeProps> = (props) => {
  const [expanded, setExpanded] = useState<string[] | null>(null);
  const [eventKey, setEventKey] = useState<EventsKey | null>(null);
  const [currentZoomLevel, setCurrentZoomLevel] = useState<number | undefined>(
    8
  );

  const mapContext = useContext(MapContext) as MapContextType;

  const { mapId, ready, layersCollection, onLayerVisibilityChange } = props;

  let mounted = false;

  const visibility = mapContext?.getLayerVisibility();
  const hiddenLayers = visibility
    ? Object.keys(visibility).filter((key) => visibility[key] === false)
    : [];
  // console.log(hiddenLayers);

  const allLayers = flattenLayers(layersCollection.getArray()).filter(
    (x) => !(x instanceof OlLayerGroup)
  );
  // console.log(allLayers);

  const visibleLayers = allLayers.filter(
    (x) => hiddenLayers.indexOf(x.get('id')) === -1
  );
  // console.log(visibleLayers);

  // const selected = visibleLayers.map(x => x.get("id"));
  const selected: string[] = [];

  const isVisible = (layer: OlBaseLayer) => {
    const id = layer.get('id');
    return visibility.hasOwnProperty(id) && visibility[id] === false
      ? false
      : true;
  };

  const handleToggle = (evt: SyntheticEvent, nodeIds: string[]): void => {
    setExpanded(nodeIds);
  };

  const handleSelect = (
    evt: ChangeEvent<{}>,
    nodeIds: string[] | string
  ): void => {
    evt.preventDefault(); //prevent onNodeToggle

    if (Array.isArray(nodeIds)) {
    } else {
      const layer = flattenLayers(layersCollection.getArray(), 5).find(
        (x) => x.get('id') === nodeIds
      );
      if (layer) {
        const layer_visible = isVisible(layer);

        const isGroup = layer instanceof OlLayerGroup;
        if (isGroup) {
          const childLayers = flattenLayers(
            layer.getLayers().getArray() as OlBaseLayer[],
            5
          );

          const childsVisible = childLayers
            ? childLayers.filter(
                (x: OlBaseLayer) =>
                  isVisible(x) === true && !(x instanceof OlLayerGroup)
              )
            : [];

          const childsHidden = childLayers
            ? childLayers.filter(
                (x: OlBaseLayer) =>
                  isVisible(x) === false && !(x instanceof OlLayerGroup)
              )
            : [];

          const groupChecked = childsVisible.length > 0;
          const groupIndeterminate =
            childsVisible.length > 0 && childsHidden.length > 0;

          let newVisible: boolean;

          if (groupIndeterminate) {
            newVisible = true;
          } else {
            newVisible = !groupChecked;
          }

          if (newVisible === true) {
            childsHidden.forEach((l) => {
              const lid = l.get('id');
              // l.setVisible(true);
              mapContext.setLayerVisibility(lid, true);
            });
          } else {
            childsVisible.forEach((l) => {
              const lid = l.get('id');
              l.setVisible(false);
              mapContext.setLayerVisibility(lid, false);
            });
          }
        } else {
          // layer.setVisible(!layer_visible);
          mapContext.setLayerVisibility(nodeIds, !layer_visible);
        }

        onLayerVisibilityChange(layer, !layer_visible);
      }
    }
  };

  const layers = layersCollection.getArray();

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);
  useEffect(() => {
    if (expanded === null) {
      try {
        const layerPickerState = localStorage.getItem("layer_pickers");
        let stateObject: Record<number, string[]> = layerPickerState ? JSON.parse(layerPickerState) : {};
        
        if (typeof stateObject !== 'object' || Array.isArray(stateObject)) {
          stateObject = {};
        }
  
        if (stateObject[mapId]) {
          if (
            Array.isArray(stateObject[mapId]) &&
            stateObject[mapId].every((item: unknown) => typeof item === 'string')
          ) {
            setExpanded(stateObject[mapId]);
          } else {
            setExpanded([]);
          }
        } else {
          setExpanded([]);
        }
      } catch (error) {
        console.error('Error accessing or parsing localStorage:', error);
        setExpanded([]);
      }
    }
  }, [mapId]);
  
  useEffect(() => {
    if (expanded !== null) {
      try {
        const layerPickerState = localStorage.getItem("layer_pickers");
        let stateObject: Record<number, string[]> = layerPickerState ? JSON.parse(layerPickerState) : {};
        
        if (typeof stateObject !== 'object' || Array.isArray(stateObject)) {
          stateObject = {};
        }
  
        stateObject[mapId] = expanded;
        localStorage.setItem("layer_pickers", JSON.stringify(stateObject));
      } catch (error) { // will happen when stateObject is not valid json object e.g. when user modifies it with gibberish
        localStorage.setItem("layer_pickers", JSON.stringify({[mapId]: expanded}))
        console.log('Error saving to localStorage:', error);
      }
    }
  }, [expanded, mapId]);
  

  useEffect(() => {
    if (mapContext.map) {
      const view = mapContext.map.getView();
      const evKey = view.on('change', (evt: BaseEvent) => {
        const v = evt.target as OlView;
        const newZoomLevel = v.getZoom();
        setCurrentZoomLevel(newZoomLevel);
      });

      setEventKey(evKey);
    }

    return () => {
      if (mounted) {
        if (mapContext?.map && eventKey) {
          const view = mapContext.map.getView();
          view.un('change', eventKey.listener);
        }
      }
    };
  }, [mapContext.map]);

  return (
    <Fragment>
      {ready && layers.length > 0 ? (
        <TreeView
          defaultCollapseIcon={<IconButton size='small'><ExpandMoreIcon /></IconButton>}
          defaultExpandIcon={<IconButton size='small'><ChevronRightIcon /></IconButton>}
          expanded={expanded ? expanded : []}
          selected={selected}
          onNodeSelect={handleSelect}
          onNodeToggle={handleToggle}
          sx={{
            mb: 2,
          }}
        >
          {layers.map((layer, i) => (
            <LayerTreeItem
              key={i}
              layer={layer}
              onNodeSelect={handleSelect}
              currentZoomLevel={mapContext.map?.getView().getZoom()}
            />
          ))}
        </TreeView>
      ) : null}
    </Fragment>
  );
};

export default LayerTree;
