import { IModel } from "@/@types/models/model";
import coreFields from "./helpers/coreFields";

const ZKVlasnik: IModel = {
  title: "Vlasnici",
  apiPath: "data/dkp/{dkp_id}/zk-vlasnici/",
  forms: {
    "default": {
      fields: ["id"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "vl", "udio", "naziv", "adresa", "oib", "opis_etaze", "opis_upisa"],
      hidden: ["opis_etaze", "opis_upisa"]
    }
  },
  fields: [
    coreFields.id,
    { 
      title: "Vlasnicki list ID",
      source: "vl",
      ttoken: "vlasnici.vlasnicki_list",
      type: "wholenum",
      readonly: true,
      filter: true,
    },
    { 
      title: "Udio",
      source: "udio",
      ttoken: "vlasnici.udio",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "Naziv",
      source: "naziv",
      ttoken: "vlasnici.naziv",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "Adresa",
      source: "adresa",
      ttoken: "vlasnici.adresa",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "OIB",
      source: "oib",
      ttoken: "common.oib",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "Vlasnički list",
      source: "ko_pl",
      ttoken: "vlasnici.vlasnicki_list",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "Katastarska općina",
      source: "ko",
      ttoken: "vlasnici.katastarska_opcina",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "Opis etaže",
      source: "opis_etaze",
      ttoken: "vlasnici.opis_etaze",
      type: "text",
      readonly: true,
      filter: true,
    },
    { 
      title: "Opis upisa",
      source: "opis_upisa",
      ttoken: "vlasnici.opis_upisa",
      type: "text",
      readonly: true,
      filter: true,
    }
  ]
}

export default ZKVlasnik;
