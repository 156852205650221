import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import numbro from 'numbro';
import moment from 'moment';
import 'moment/locale/hr';
import useApi from './lib/api/useApi';

i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    ns: [
      'core',
      'map',
      'ena',
      'lighting',
      '3D',
      'admin',
      'photo',
      'groblja',
      'nerazvrstane_ceste',
      'rasvjeta',
      'parking',
    ],
    defaultNS: 'core',
    // lng: "hr",
    fallbackLng: 'hr',
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      // TODO see on what endpoint will translations be
      loadPath: 'core/translations/{{lng}}/{{ns}}',
      request: (
        options: object,
        url: string,
        payload: object,
        callback: Function
      ) => {
        const apiInstance = useApi();
        return apiInstance
          .get(url)
          .then((resp) => {
            if (resp.success) {
              callback(null, {
                status: 200,
                data: JSON.stringify(resp.data) || '',
              });
            }
          })
          .catch((err) => {
            callback(err);
          });
      },
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
  numbro.setLanguage(lng);
});

export default i18n;
