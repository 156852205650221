/* eslint-disable */
import React, {useEffect, useContext, useRef, ReactNode, useMemo} from 'react'


import DragableRoot from '../DraggableRoot';
import { Box } from '@mui/material';
import GSInfoCard, { GSInfoCardProps } from '@/components/Map/InfoCards/GSInfoCard';

import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';
import PPVInfoCard from '@/components/Map/InfoCards/PPVInfoCard';
import { IGFICardOverlayData, IGFIOverlayFeatureIndex } from '@/@types/views/map';

type DraggaBleInfoCardControlProps = {
    GFICardOverlay: IGFICardOverlayData[] | undefined;
    overlayTabActive: number;
    closeGFIOverlay: () => void;
    setIndex: React.Dispatch<React.SetStateAction<IGFIOverlayFeatureIndex>>;
    currentIndex: any;
    handlePopoverOverlayClick: any;
    handleSetDetach: (newState: boolean) => void;
    isDetached: boolean;
}

const DraggableInfoCardControl = (props: DraggaBleInfoCardControlProps) => {
  
    const { GFICardOverlay, overlayTabActive, currentIndex, isDetached } = props;
    const { closeGFIOverlay, setIndex, handlePopoverOverlayClick, handleSetDetach } = props;

    const position = useMemo(()=>{
      if (GFICardOverlay) {
        return GFICardOverlay[overlayTabActive].position
      } else return []
    },[GFICardOverlay, overlayTabActive])
    
    return (
      //@ts-ignore bcuz of missing ref. Idk how to fix it, its like this in every control
        <DragableRoot
          defaultClassName={'gs-info-card-draggable'}
          id={'draggable-gs-info-card'}
          handleClassname={'.MuiCardHeader-root'}
          position={position}
          isVisible={isDetached}
        >
            {isDetached && GFICardOverlay && GFICardOverlay.length > overlayTabActive ? (
              GFICardOverlay[overlayTabActive].layerData
                .layerFunctionalityId ===
                EnumLayerFunctionality.cjenovni_blok_custom_actions ? (
                <PPVInfoCard
                  featureData={GFICardOverlay[overlayTabActive]}
                  onClose={closeGFIOverlay}
                  layerPickerDisabled={GFICardOverlay.length <= 1}
                  setIndex={setIndex}
                  currentIndex={currentIndex}
                  handlePopoverOverlayClick={handlePopoverOverlayClick}
                />
              ) : (
                <GSInfoCard
                  isDetached={isDetached}
                  handleSetDetach={handleSetDetach}
                  featureData={GFICardOverlay[overlayTabActive]}
                  onClose={closeGFIOverlay}
                  layerPickerDisabled={GFICardOverlay.length <= 1}
                  setIndex={setIndex}
                  currentIndex={currentIndex}
                  handlePopoverOverlayClick={handlePopoverOverlayClick}
                />
              )
            ) : (
              <></>
            )}
            
        </DragableRoot>
    )
}

export default DraggableInfoCardControl;