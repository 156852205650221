/* eslint-disable */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

//Material UI
import Toolbar from '@mui/material/Toolbar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';

//Custom Icons
import ThreeDSettingIcon from '@/icons/ThreeDSettingIcon';

//Custom components
import FormContent from '@/components/FormContent';
import { GridContainer, GridItem } from '@/ui/Grid';
import {
  CardActionButtonSave,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';
import {
  FormAvatar,
  FormHeading,
  FormHeadingID,
  FormFillContent,
} from '@/ui/Form';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import dataController from '@/lib/DataController';
import useFormController from '@/components/useFormController';
import { FormContentMode } from '@/components/FormContent';
import { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';

interface IModelFormProps {
  dc: dataController;
  recordId: number;
  mode: FormContentMode;
  form: string;
}

function ThreeDSettingForm(props: IModelFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const { dc, recordId, mode, form } = props;
  const {
    record,
    validation,
    fields,
    onFieldChange,
    doUpdate,
    doDelete,
    doClose,
    pickerDataControllers,
    dataChanged,
    doRefresh
  } = useFormController({
    mode,
    recordId: recordId,
    form,
    dc,
    onClose: close,
  });

  const handleClickBack = () => {
    if (doClose) {
      doClose()
        .then((resp) => {
          if (resp.success) {
            navigate('/admin/3d_settings');
          } else if (resp.shouldSave) {
            if (doUpdate) {
              doUpdate()
                .then((result) => {
                  if (result.success) {
                    const msg = 'messages.update_3d_setting_success';
                    snackbarContext.showNotification(msg, 'success');
                    navigate('/admin/3d_settings');
                  }
                })
                .catch((result) => {
                  if (
                    result.success === false &&
                    result.validationPass === false
                  ) {
                    //do nothing
                  } else {
                    const msg = 'error.update_3d_setting';
                    snackbarContext.showNotification(msg, 'error');
                  }
                });
            }
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, 'error');
        });
    }
  };

  const handleUpdate = (evt: any) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.update_3d_setting_success';
            snackbarContext.showNotification(msg, 'success');
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            //do nothing
          } else {
            const msg = 'error.update_3d_setting';
            snackbarContext.showNotification(msg, 'error');
          }
        })
        .finally(() => {
          doRefresh();
        });
    }
  };

  const handleDelete = (evt: any) => {
    if (doDelete) {
      doDelete()
        .then((result) => {
          if (result.success) {
            const msg = 'messages.delete_3d_setting_success';
            snackbarContext.showNotification(msg, 'success');
            navigate('/admin/3d_settings');
          }
        })
        .catch((result) => {
          const msg = 'error.delete_3d_setting';
          snackbarContext.showNotification(msg, 'error');
        });
    }
  };

  const title = record.cesium_folder
    ? (record.cesium_folder as string)
    : undefined;

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="model" icon={<ThreeDSettingIcon />} />
          <FormHeading
            header={title}
            subheaderContent={<FormHeadingID id={recordId} />}
            dataChanged={dataChanged}
          />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
          <CardToolbarMenuButton>
            <CardToolbarMenuItemDelete onClick={handleDelete} />
          </CardToolbarMenuButton>
        </Toolbar>
      </CardHeading>
      <CardContent>
        <GridContainer direction="row" spacing={3}>
          <GridItem>
            <GridContainer direction="row">
              <FormContent
                fieldNames={[
                  'id',
                  'tenant_id',
                  'cesium_folder',
                  'cesium_config_file',
                ]}
                record={record}
                validation={validation}
                onFieldChange={onFieldChange}
                fields={fields}
                mode={mode}
                pickerDataControllers={pickerDataControllers}
                columns={1}
              />
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardContent>
      <CardActions>
        <FormFillContent />
        <CardActionButtonSave
          onClick={handleUpdate}
          variant="contained"
          disabled={!dataChanged}
        />
      </CardActions>
    </Card>
  );
}

export default ThreeDSettingForm;
