/* eslint-disable */
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import {
  Box,
  CardContent,
  Fab,
  Menu,
  Pagination,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// MUI Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Custom Components
import MapCard from '@/ui/MapCard/MapCard';
import MapCardHeader from '@/ui/MapCard/MapCardHeader';
import MapCardAvatar from '@/ui/MapCard/MapCardAvatar';
import MapCardContent from '@/ui/MapCard/MapCardContent';
import MapCardHeaderButtonClose from '@/ui/MapCard/MapCardHeaderButtonClose';
import MapCardHeaderButtonLayers from '@/ui/MapCard/MapCardHeaderButtonLayers';
import { GridContainer, GridItem } from '@/ui/Grid';
import FormContent from '@/components/FormContent';
import PosjedniciMenuListItem from './LayerFunctionalities/PosjedniciMenuListItem';
import VlasniciMenuListItem from './LayerFunctionalities/VlasniciMenuListItem';
import Show3DButtonListItem from './LayerFunctionalities/Show3DButtonListItem';
import GoogleMapsListItem from './LayerFunctionalities/GoogleMapsListItem';
import DialogContext from '@/context/DialogContext/DialogContext';
import GSInfoDialog from './Dialogs/GSInfoDialog';

// Models
import modelData from '@/models/data';

// Types
import {
  IGFICardOverlayData,
  IGFIOverlayFeatureIndex,
} from '@/@types/views/map';
import { DCRecord } from '@/@types/lib/dataController';
import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';
import PosjedniciButton from './LayerFunctionalities/PosjedniciButton';
import VlasniciButton from './LayerFunctionalities/VlasniciButton';
import MapCardActions from '@/ui/MapCard/MapCardActions';
import Permissions from '@/lib/permissions';
import UserContext from '@/context/UserContext/UserContext';
import AttachDocumentButtonListItem from './LayerFunctionalities/AttachDocumentButtonListItem';
import KuceZaOdmorButton from './LayerFunctionalities/KuceZaOdmorButton';
import DetachListItem from './LayerFunctionalities/DetachListItem';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { GFIFeatureType } from '@/@types/services/gsService';
import ShowKOButton from './LayerFunctionalities/ShowKOButton';
import ShowPhotodocButton from './LayerFunctionalities/ShowPhotodocButton';
import UkopaniButton from './LayerFunctionalities/UkopaniButton';
import CommentListItem from './LayerFunctionalities/CommentListItem';

export interface GSInfoCardProps {
  featureData: IGFICardOverlayData;
  onClose: () => void;
  setIndex: React.Dispatch<React.SetStateAction<IGFIOverlayFeatureIndex>>;
  currentIndex: any;
  handlePopoverOverlayClick: any;
  layerPickerDisabled?: boolean;
  handleSetDetach: (newState: boolean) => void;
  isDetached: boolean;
}

const GSInfoCard = (props: GSInfoCardProps) => {
  const { t } = useTranslation();

  const {
    featureData,
    onClose,
    setIndex,
    currentIndex,
    handlePopoverOverlayClick,
    layerPickerDisabled,
    handleSetDetach,
    isDetached,
  } = props;

  const [dataCache, setDataCache] = useState<
    Map<number, Map<String, DCRecord[]>>
  >(new Map<number, Map<String, DCRecord[]>>()); // To save requests made by Menu List Items (e.g. Vlasnici, Posjednici fetch)

  const dialogContext = useContext(DialogContext);
  const userContext = useContext(UserContext);

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const actionsMenuOpen = Boolean(actionsMenuAnchorEl);
  const handleOpenActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setActionsMenuAnchorEl(event.currentTarget);
  };
  const handleCloseActionsMenu = () => {
    setActionsMenuAnchorEl(null);
  };

  /*const handleExpand = (node: ReactNode, identifier: string) => { 
    if (expanded === identifier) {
      setExpanded(null);
      setTimeout(() => {
        setCollapsedNode(null);
      }, 300); // Allow time for collapse animation
    } else {
      setCollapsedNode(node);
      if (expanded !== null) {
        setExpanded(identifier);
      } else {
        setTimeout(() => {
          setExpanded(identifier);
        }, 300); // Allow time for collapse animation
      }
      }
  };*/

  const { layerData } = featureData;

  const { layer, model, layerFunctionalityId } = layerData;

  const modelFields =
    model?.fields && model?.fields.length > 0 ? model.fields : modelData.fields; // default to data.ts model

  /*const [expandedKuceZaOdmor, setExpandedKuceZaOdmor] =
    useState<boolean>(false);*/

  const faIconClass = 'fas fa-info ';

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const record = useMemo(() => {
    if (featureData) {
      if (featureData.record) {
        return featureData.record;
      } else if (featureData.features[currentIndex[layer]]) {
        return featureData.features[currentIndex[layer]].properties;
      } else if (featureData.features[0]) {
        return featureData.features[0].properties;
      }
    }
    return null;
  }, [featureData, currentIndex, layer]);

  const feature = useMemo(() => {
    if (featureData) {
      if (featureData.features[currentIndex[layer]]) {
        return featureData.features[currentIndex[layer]];
      } 
    }
    return null;
  }, [featureData, currentIndex, layer]);

  useEffect(() => {
    if (featureData && featureData.features[0] && !featureData.record) {
      if (!currentIndex[layer]) { // if it's not already set initialize it
        setIndex((prev) => {
          const obj = { ...prev };
          obj[layer] = 0; // Move the state update to `useEffect`
          return obj;
        });
      }
    }
  }, [featureData, setIndex, layer]);

  const handleAddToCache = (
    identifier: string,
    recordId: number,
    data: DCRecord[]
  ) => {
    setDataCache((prevCache) => {
      const newCache = new Map(prevCache); // Create a new Map from the previous state
      const recordCache =
        newCache.get(recordId) || new Map<string, DCRecord[]>();
      recordCache.set(identifier, data);
      newCache.set(recordId, recordCache);
      return newCache; // Return the new Map to trigger a state update
    });
  };

  const handleGetFromCache = (
    identifier: string,
    recordId: number
  ): DCRecord[] | null => {
    const recordCache = dataCache.get(recordId);
    if (recordCache) {
      return recordCache.get(identifier) || null;
    }
    return null;
  };

  const generateCustomActions = (
    layerFunctionalityId: number | null
  ): ReactNode[] => {
    let nodes: ReactNode[] = [];

    switch (layerFunctionalityId) {
      case EnumLayerFunctionality.dkp_show_posjednici_vlasnici:
        nodes.push(
          <PosjedniciButton
            key={'posjednici-btn'}
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        nodes.push(
          <VlasniciButton
            key={'vlasnici-btn'}
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        break;
      case EnumLayerFunctionality.dkp_show_posjednici:
        nodes.push(
          <PosjedniciButton
            key={'posjednici-btn'}
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        break;
      case EnumLayerFunctionality.kuce_za_odmor_button:
        nodes.push(
          <KuceZaOdmorButton
            key="kzo-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
            handleCloseMenu={() => {
              dialogContext.hideDialog();
            }}
          />
        );
        break;
      case EnumLayerFunctionality.objekti_ko_photodoc:
        nodes.push(
          <ShowKOButton
            key="ko-btn"
            featureData={featureData}
            layerIndex={currentIndex[featureData.layerData.layer]}
          />
        );
        nodes.push(
          <ShowPhotodocButton
            key="photodoc-btn"
            featureData={featureData}
            layerIndex={currentIndex[featureData.layerData.layer]}
          />
        );
        break;
      case EnumLayerFunctionality.grobna_mjesta_show_ukopani:
        nodes.push(
          <UkopaniButton
            key="ukopani-btn"
            featureData={featureData}
            record={record}
            onToggle={handleOpenDialog}
            addToCache={handleAddToCache}
            getFromCache={handleGetFromCache}
          />
        );
        break;
      default:
        break;
    }
    return nodes;
  };

  const generateActionList = (
    layerFunctionalityId: number | null
  ): ReactNode[] => {
    let nodes: ReactNode[] = [];

    if (userContext) {
      userContext.hasAnyPermission([Permissions.ThreeDView])
        ? nodes.push(
            // Will not render if client does not have permission for 3D
            <Show3DButtonListItem
              key={'show-3d-li'}
              handleCloseMenu={handleCloseActionsMenu}
              featureData={featureData}
            />
          )
        : null;
    }

    if (record.gid) {
      if (userContext?.hasAnyPermission([Permissions.Private])) {
        nodes.push(
          <AttachDocumentButtonListItem
            key="attach-document-li"
            handleCloseMenu={handleCloseActionsMenu}
            gid={record.gid}
          />,
          <CommentListItem
            key="comment-li"
            handleCloseMenu={handleCloseActionsMenu}
            gid={record.gid}
            feature={featureData.features[currentIndex[layer]]}
          />
        );
      }
    }

    switch (layerFunctionalityId) {
      // Posjednici and vlasnici will be buttons.
      /*case EnumLayerFunctionality.dkp_show_posjednici_vlasnici:
        nodes.push(
          <PosjedniciMenuListItem
          key={"posjednici-li"}
          handleCloseMenu={handleCloseActionsMenu}
          featureData={featureData}
          record={record}
          onToggle={handleOpenDialog}
          addToCache={handleAddToCache}
          getFromCache={handleGetFromCache}
          />
        );
        nodes.push(
          <VlasniciMenuListItem
          key={"vlasnici-li"}
          handleCloseMenu={handleCloseActionsMenu}
          featureData={featureData}
          record={record}
          onToggle={handleOpenDialog}
          addToCache={handleAddToCache}
          getFromCache={handleGetFromCache}
          />
        )
        break;
      case EnumLayerFunctionality.dkp_show_posjednici:
        nodes.push(
          <PosjedniciMenuListItem
          key={"posjednici-li"}
          featureData={featureData}
          record={record}
          onToggle={handleOpenDialog}
          addToCache={handleAddToCache}
          getFromCache={handleGetFromCache}
          />
        );
        break;*/
      case EnumLayerFunctionality.odvoz_location_button:
        break;
      case EnumLayerFunctionality.kuce_za_odmor_button:
        break;
      case EnumLayerFunctionality.google_maps_navigate_button:
        nodes.push(
          <GoogleMapsListItem
            key={'google-maps-li'}
            featureData={featureData}
          />
        );
        break;
      default:
        break;
    }
    nodes.push(
      <DetachListItem
        isDetached={isDetached}
        key={'detach-li'}
        handleDetach={handleSetDetach}
        closeMenu={handleCloseActionsMenu}
      />
    );

    return nodes;
  };

  const fields = modelFields;
  const fieldNames = modelFields.map((field) => field.source);

  const recordId = record.id ? record.id : record.fid ? record.fid : null;

  // const handleToggleKuceZaOdmor = () => {
  //   const newValue = !expandedKuceZaOdmor;
  //   setExpandedKuceZaOdmor(newValue);
  // };

  // const handleOdvozLocationRedirect = () => {
  //   const locId =
  //     initialRecod.lokacija_i === null
  //       ? ""
  //       : `&loc_id=${initialRecod.lokacija_i}`;
  //   if (locId !== "") {
  //     const url =
  //       location.origin +
  //       "/komunalne-obveze/?" +
  //       locId.toString() +
  //       "&open_first=true";
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/komunalne-obveze";
  //     window.open(url, "_blank");
  //   }
  // };

  // const handleKomunaneObvezeRedirect = () => {
  //   const locId =
  //   initialRecod.lokacija_id === null
  //   ? ""
  //   : `&loc_id=${initialRecod.lokacija_id}`;
  //   if (locId !== "") {
  //     const url =
  //       location.origin +
  //       "/komunalne-obveze/?" +
  //       locId.toString() +
  //       "&open_first=true";
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/komunalne-obveze";
  //     window.open(url, "_blank");
  //   }
  // };

  // const handleFotodokumentacijaRedirect = () => {
  //   const objektId =
  //     initialRecod.objekt_id === null
  //       ? ""
  //       : `${initialRecod.objekt_id}`;
  //   console.log(initialRecod)
  //   if (objektId !== "") {
  //     const url =
  //       location.origin +
  //       "/fotodokumentacija-objekata/?rec_id=" + objektId.toString();
  //     window.open(url, "_blank");
  //   } else {
  //     const url = location.origin + "/fotodokumentacija-objekata";
  //     window.open(url, "_blank");
  //   }
  // };

  const modelTitle =
    model?.title_type === 1
      ? t(`${model.title_token}`)
      : model?.title_type === 2 && model?.title_field
      ? record[model.title_field]
      : model?.title_type === 3 && model?.title_field
      ? `${t(`${model.title_token}`)}: ${record[model.title_field]}`
      : null;

  const title: string = model
    ? modelTitle
    : record.naziv
    ? record.naziv
    : layer
    ? t(`map:layer.${layer}`)
    : 'Info';

  const GSFormContent = () => {
    return (
      <GridContainer spacing={0}>
        <FormContent
          fields={fields}
          fieldNames={fieldNames}
          columns={1}
          mode="simpleView"
          record={record}
        />
      </GridContainer>
    );
  };

  const handleOpenDialog = (node: ReactNode, identifier: string) => {
    if (dialogContext) {
      dialogContext.showDialog(GSInfoDialog, {
        body: node,
        handleClose: () => {
          dialogContext.hideDialog();
        },
        identifier: identifier,
        FormContent: GSFormContent,
      });
    }
  };

  const customActionList = generateActionList(layerFunctionalityId);
  const customActions = generateCustomActions(layerFunctionalityId);

  return (
    <MapCard
      sx={{
        backgroundColor: 'background.default',
        width: 'min-content',
        maxWidth: { xs: '300px', md: '500px' },
      }}
    >
      <MapCardHeader
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              maxWidth: '100%',
            }}
          >
            <Box
              display={'flex'}
              maxWidth={"100%"}
              sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            >
              <Stack justifyContent="center" color="white">
                <Typography
                  noWrap={title && title.length > 45 ? false : true}
                  fontSize={'0.9rem'}
                  lineHeight={1.15}
                  variant="body1"
                  sx={{maxWidth: "100%"}}
                >
                  {title}
                </Typography>
                {recordId && recordId !== null ? (
                  <Typography
                    noWrap
                    fontSize={'0.85rem'}
                    lineHeight={1.15}
                    sx={{ display: { xs: 'none', md: 'block' },maxWidth: "100%"}}
                    variant="body2"
                  >{`${t('common.id')}: ${recordId}`}</Typography>
                ) : null}
              </Stack>
            </Box>
          </div>
        }
        disableTypography
        avatar={
          <MapCardAvatar
            ariaLabel="info"
            icon={<i className={faIconClass} />}
          />
        }
        action={
          <Stack m={0} direction="row">
            {!layerPickerDisabled && (
              <MapCardHeaderButtonLayers onClick={handlePopoverOverlayClick} />
            )}
            <MapCardHeaderButtonClose onClick={onClose} />
          </Stack>
        }
      />
      {featureData.features && featureData.features.length > 1 ? (
        <Box width={'100%'} borderBottom={'0.1px solid lightgray'}>
          <Tabs
            sx={{ minHeight: '24px', height: '24px' }}
            variant="scrollable"
            scrollButtons="auto"
            value={currentIndex[layer]}
            onChange={(event, value) => {
              setIndex((prev: any) => {
                const obj = { ...prev };
                obj[layer] = value;
                return obj;
              });
            }}
            aria-label="pagination tabs"
            TabScrollButtonProps={{
              sx: {
                transition: 'width 0.15s',
                '&.Mui-disabled': {
                  width: 0,
                },
              },
            }}
          >
            {featureData.features.map((feat: GFIFeatureType, index: number) => (
              <Tab
                key={index}
                sx={{
                  flexGrow: 1,
                  borderRight:
                    index !== featureData.features.length - 1
                      ? '0.1px solid lightgray'
                      : '',
                  p: 0,
                  height: '24px',
                  minHeight: '24px',
                  minWidth: '48px',
                }}
                label={index + 1}
              />
            ))}
          </Tabs>
        </Box>
      ) : null}
      <CardContent
        sx={{
          p: 1,
          '&:last-child': {
            paddingBottom: 1,
          },
          width: "100%"
        }}
      >
        <Box display={'flex'} gap={1} width={"100%"}>
          <GridContainer spacing={0} width={'calc( 100% - 40px )'}>
            <GSFormContent />
          </GridContainer>
          {!customActions.length && customActionList.length ? (
            <>
              <Fab
                color="primary"
                aria-label="more"
                id="long-button"
                aria-controls={actionsMenuOpen ? 'long-menu' : undefined}
                aria-expanded={actionsMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenActionsMenu}
                size="small"
                sx={{
                  minWidth: '32px',
                  minHeight: '32px',
                  height: '32px',
                  width: '32px',
                  alignSelf: 'flex-end',
                }}
              >
                <MoreVertIcon fontSize="medium" />
              </Fab>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                sx={{ p: 0 }}
                anchorEl={actionsMenuAnchorEl}
                open={actionsMenuOpen}
                onClose={handleCloseActionsMenu}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                {customActionList}
              </Menu>
            </>
          ) : null}
        </Box>
      </CardContent>
      {customActions.length ? (
        <MapCardActions>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            sx={{ gap: { xs: 1, md: 0 } }}
          >
            <Box
              display={'flex'}
              width={'100%'}
              sx={{ flexDirection: { xs: 'column', md: 'row' } }}
            >
              {customActions}
            </Box>
            {customActionList.length ? (
              <Box
                width={'fit-content'}
                display={'flex'}
                alignItems={'flex-end'}
              >
                <Fab
                  color="primary"
                  aria-label="more"
                  id="long-button"
                  aria-controls={actionsMenuOpen ? 'long-menu' : undefined}
                  aria-expanded={actionsMenuOpen ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleOpenActionsMenu}
                  size="small"
                  sx={{
                    minWidth: '32px',
                    minHeight: '32px',
                    height: '32px',
                    width: '32px',
                    alignSelf: 'flex-end',
                  }}
                >
                  <MoreVertIcon fontSize="medium" />
                </Fab>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  sx={{ p: 0 }}
                  anchorEl={actionsMenuAnchorEl}
                  open={actionsMenuOpen}
                  onClose={handleCloseActionsMenu}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                  {customActionList}
                </Menu>
              </Box>
            ) : null}
          </Box>
        </MapCardActions>
      ) : null}
    </MapCard>
  );
};

export default GSInfoCard;
