/* eslint-disable import/prefer-default-export */
// disabled because there will be more enums in the future, remove when there are two
export enum EnumLayerFunctionality {
    dkp_show_posjednici_vlasnici = 1,
    dkp_show_posjednici,
    cjenovni_blok_custom_actions,
    odvoz_location_button,
    kuce_za_odmor_button,
    toggle_dof,
    google_maps_navigate_button,
    objekti_ko_photodoc,
    grobna_mjesta_show_ukopani,
    toggle_objekti
  }
