/* eslint-disable */
import * as React from 'react';
import { useState, useEffect, useRef, FunctionComponent } from 'react';

// OpenLayers
import OlMap from 'ol/Map';
import OlView from 'ol/View';
// import { MapOptions } from "ol/PluggableMap";
import { EventsKey } from 'ol/events';

import { MapOptions } from '@/@types/components/Map/Interfaces';

// Custom components
import MapContextProvider from '@/context/MapContext/MapContextProvider';
import { findChild, getDefinedOptions, getEvents } from '@/lib/olHelpers';
import { Controls } from './Controls';
import { Layers } from './Layers';
import { Overlays } from './Overlays';
import { Interactions } from './Interactions';

// Types
import { IMap } from '@/@types/components/Map';
import { InitOptionsType } from '@/context/MapContext/MapContext';

const Map: FunctionComponent<IMap> = (props: React.PropsWithChildren<IMap>) => {
  const mapElementRef = useRef();
  const {
    id,
    height,
    className,
    zoomToExtent,
    zoomToExtentPadding,
    onPointermove,
    onClick,
    onMoveend,
    view,
    children,
    mapId
  } = props;

  // we use useRef, as it provides us mutable object that persists between re-renders
  const map = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const [eventKeys, setEventKeys] = useState<EventsKey[]>([]);

  const initOptions: InitOptionsType = {
    controls: [],
    layers: [],
    overlays: [],
    interactions: [],
  };

  const options = {
    controls: undefined,
    pixelRatio: undefined,
    interactions: undefined,
    keyboardEventTarget: undefined,
    layers: undefined,
    maxTilesLoading: undefined,
    moveTolerance: undefined,
    overlays: undefined,
    target: undefined,
    view: new OlView({ center: [0, 0], zoom: 3 }),
  };

  const events = {
    change: undefined,
    'change:layerGroup': undefined,
    'change:size': undefined,
    'change:target': undefined,
    'change:view': undefined,
    click: undefined,
    dblclick: undefined,
    error: undefined,
    moveend: undefined,
    movestart: undefined,
    pointerdrag: undefined,
    pointermove: undefined,
    postcompose: undefined,
    postrender: undefined,
    precompose: undefined,
    propertychange: undefined,
    rendercomplete: undefined,
    singleclick: undefined,
  };

  const controlsComp = findChild(children, Controls);
  const layersComp = findChild(children, Layers);
  const overlaysComp = findChild(children, Overlays);
  const interactionsComp = findChild(children, Interactions);

  useEffect(() => {
    // console.log('Map use effect');
    if (!map.current) {
      //console.log('Map use effect -> init', initOptions);
      const allOptions = Object.assign(options, props);
      const mapOptions: MapOptions = getDefinedOptions(allOptions);
      !(mapOptions.view instanceof OlView) &&
        (mapOptions.view = new OlView(options.view));

      if (controlsComp) {
        mapOptions.controls = initOptions.controls;
      } else {
        // do nothing, we leave controls prop undefined -> OpenLayers will assign default controls
      }
      mapOptions.layers = initOptions.layers;
      mapOptions.overlays = initOptions.overlays;

      if (interactionsComp) {
        mapOptions.interactions = initOptions.interactions;
      } else {
        // do nothing, we leave interactions prop undefined -> OpenLayers will assign defult interactions
      }

      const newMap = new OlMap(mapOptions);
      newMap.setTarget(mapElementRef.current);

      // regitster events
      const olEvents = getEvents(events, props);
      const evKeys: EventsKey[] = [];
      for (const eventName in olEvents) {
        // @ts-ignore
        const evKey = newMap.on(eventName, olEvents[eventName]);
        evKeys.push(evKey);
      }

      // assign newMap to our ref object
      // @ts-ignore TODO: Type Map is not assignable to type null
      map.current = newMap;
      setInitialized(true);
      setEventKeys(evKeys);
    }
  });

  useEffect(() => {
    const padding = zoomToExtentPadding || [100, 100, 100, 100];
    if (map.current && zoomToExtent) {
      // @ts-ignore TODO: property getView does not exist on type never
      map.current.getView().fit(zoomToExtent, {
        // @ts-ignore TODO: property getSize does not exist on type never
        size: map.current.getSize(),
        padding,
      });
    }
  }, [zoomToExtent]);

  useEffect(() => {
    if (map.current && view) {
      // @ts-ignore TODO: property getView does not exist on type never and center does not exist on type View
      map.current.getView().setCenter(view.center);
    }
    // @ts-ignore TODO: object is possibly undefined
  }, [view.center]);

  useEffect(() => {
    if (map.current) {
      const evKey = eventKeys.find((x) => x.type === 'click');
      if (evKey) {
        // @ts-ignore
        map.current.un('click', evKey.listener);
      }
      // @ts-ignore
      const newEvKey = map.current.on('click', onClick);
      setEventKeys((prevState) => [
        ...prevState.filter((x) => x.type !== 'click'),
        newEvKey,
      ]);
    }
  }, [onClick]);

  return (
    <MapContextProvider mapId={mapId} map={map.current} initOptions={initOptions}>
      <div className="map-container">
        {/* @ts-ignore TODO: type undefined is not assignable to type HTMLDivElement | null */}
        <div
          id={id || 'testmap'}
          className="map openlayers-map sidebar-map"
          // @ts-ignore
          ref={mapElementRef}
        >
          {/* @ts-ignore TODO:Property 'props' does not exist on type '{} | ReactElement<any, string | JSXElementConstructor<any>>' */}
          {layersComp ? React.createElement(Layers, layersComp.props) : null}
          {/* @ts-ignore TODO:Property 'props' does not exist on type '{} | ReactElement<any, string | JSXElementConstructor<any>>' */}
          {controlsComp
            ? // @ts-ignore
              React.createElement(Controls, controlsComp.props)
            : null}
          {/* @ts-ignore TODO:Property 'props' does not exist on type '{} | ReactElement<any, string | JSXElementConstructor<any>>' */}
          {interactionsComp
            ? // @ts-ignore
              React.createElement(Interactions, interactionsComp.props)
            : null}
        </div>
        {/* @ts-ignore TODO:Property 'props' does not exist on type '{} | ReactElement<any, string | JSXElementConstructor<any>>' */}
        {overlaysComp
          ? // @ts-ignore
            React.createElement(Overlays, overlaysComp.props)
          : null}
      </div>
    </MapContextProvider>
  );
};

export default Map;
