import { IModel } from '@/@types/models/model';

import commonFields from './helpers/commonFields';
import buttonFields from './helpers/buttonFields';
import coreFields from './helpers/coreFields';

const KomunalniObveznici: IModel = {
  title: 'Komunalni obveznici',
  source: 'komunalni_obveznici',
  exportFileName: 'komunalni_obveznici',
  apiPath: 'ena/komunalni-obveznici',
  forms: {
    default: {
      fields: [
        'obveznik',
        'obveznik_adresa',
        'narucitelj_objekt_sifra',
        'obveznik_adresa',
        'obveznik_naselje',
        'objekt_adresa',
        'objekt_naselje',
        'lokacija_id',
        'lokacija_id_short',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'obveznik',
        'obveznik_oib',
        'narucitelj_sifra_obveznika',
        // 'vrsta_prostora',
        'narucitelj_p_a',
        'p_a',
        'narucitelj_p_b',
        'p_b',
        // 'narucitelj_p_c',
        // 'p_c',
        'obveznik_adresa',
        'obveznik_naselje',
        'objekt_adresa',
        'objekt_naselje',
        'namjena_id',
        'lokacija_id_short',
        'obveznik_status_id',
        'docs',
        'active',
        'odstupanje',
        'btn_show_photos',
        'download',
      ],
      hidden: [
        'id',
        'obveznik_oib',
        'obveznik_adresa',
        'obveznik_naselje',
        'lokacija_id_short',
        'narucitelj_p_a',
        'narucitelj_p_b',
      ],
    },
  },
  fields: [
    /*
     ** IDs
     */
    {
      title: 'id',
      source: 'id',
      ttoken: 'common.id',
      type: 'numeric',
      idattr: true,
      readonly: true,
      filter: true,
    },
    {
      title: 'Lokacija ID',
      source: 'lokacija_id',
      ttoken: 'ena:komunalni_obveznici.lok',
      type: 'text',
      filter: true,
    },
    {
      title: 'Lokacija ID short',
      source: 'lokacija_id_short',
      ttoken: 'ena:komunalni_obveznici.lok',
      type: 'text',
      filter: true,
    },
    {
      title: 'Glavni Objekt ID',
      source: 'glavniobj_id',
      ttoken: 'komunalni_obveznici.lok',
      type: 'text',
      filter: true,
    },
    /*
     ** Obveznik
     */
    {
      title: 'Obveznik',
      source: 'obveznik',
      ttoken: 'ena:komunalni_obveznici.obv',
      type: 'text',
      filter: true,
    },
    {
      title: 'OIB obveznika',
      source: 'obveznik_oib',
      ttoken: 'common.oib',
      type: 'text',
      filter: true,
    },
    {
      title: 'Status',
      source: 'obveznik_status_id',
      ttoken: 'ena:komunalni_obveznici.status',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'Adresa obveznika',
      source: 'obveznik_adresa',
      ttoken: 'ena:komunalni_obveznici.adrobv',
      type: 'text',
      filter: true,
    },
    {
      title: 'Sifra obveznika',
      source: 'narucitelj_sifra_obveznika',
      ttoken: 'ena:komunalni_obveznici.sifobv',
      type: 'text',
      filter: true,
    },
    {
      title: 'Naselje obveznika',
      source: 'obveznik_naselje',
      ttoken: 'ena:komunalni_obveznici.nasobv',
      type: 'text',
      filter: true,
    },
    /*
     ** Objekt
     */
    {
      title: 'sifobj',
      source: 'narucitelj_objekt_sifra',
      ttoken: 'ena:komunalni_obveznici.sifobj',
      type: 'text',
      filter: true,
    },
    {
      title: 'adrobj',
      source: 'objekt_adresa',
      ttoken: 'ena:komunalni_obveznici.adrobj',
      type: 'text',
      filter: true,
    },
    {
      title: 'nasobj',
      source: 'objekt_naselje',
      ttoken: 'ena:komunalni_obveznici.nasobj',
      type: 'text',
      filter: true,
    },
    /*
     ** Namjena i povrsine
     */
    {
      title: 'namjena',
      source: 'namjena_id',
      ttoken: 'ena:komunalni_obveznici.namjena_poslije',
      type: 'picker',
      filter: true,
      items: {
        labels: [
          'ena:namjena.s',
          'ena:namjena.p',
          'ena:namjena.g',
          'ena:namjena.b',
          'ena:namjena.k',
          'ena:namjena.j',
          'ena:namjena.a',
          'ena:namjena.o',
          'ena:namjena.t',
          'ena:namjena.z',
          'ena:namjena.f',
          'ena:namjena.n',
        ],
        values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
    },
    {
      title: 'Povrsina A',
      source: 'p_a',
      ttoken: 'ena:komunalni_obveznici.pov_a',
      tooltip: 'ena:komunalni_obveznici.tooltips.pov_a',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Povrsina B',
      source: 'p_b',
      ttoken: 'ena:komunalni_obveznici.pov_b',
      tooltip: 'ena:komunalni_obveznici.tooltips.pov_b',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Povrsina C',
      source: 'p_c',
      ttoken: 'ena:komunalni_obveznici.pov_c',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Povrsina A od narucitelja',
      source: 'narucitelj_p_a',
      ttoken: 'ena:komunalni_obveznici.nar_pov_a',
      tooltip: 'ena:komunalni_obveznici.tooltips.pov_a',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Povrsina B od narucitelja',
      source: 'narucitelj_p_b',
      ttoken: 'ena:komunalni_obveznici.nar_pov_b',
      tooltip: 'ena:komunalni_obveznici.tooltips.pov_b',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Povrsina C od narucitelja',
      source: 'narucitelj_p_c',
      ttoken: 'ena:komunalni_obveznici.nar_pov_c',
      type: 'numeric',
      filter: true,
    },
    // {
    //   title: "Zona",
    //   source: "zona",
    //   ttoken: "komunalni_obveznici.zona",
    //   type: "text",
    //   search: false
    // },
    // {
    //   title: "Katastarska opcina",
    //   source: "ko",
    //   ttoken: "komunalni_obveznici.ko",
    //   type: "text",
    //   search: true
    // },
    // {
    //   title: "Katastarska cestica",
    //   source: "kc",
    //   ttoken: "komunalni_obveznici.kc",
    //   type: "text",
    //   search: true
    // },
    // {
    //   title: "Vrsta prostora",
    //   source: "vrsta_prostora",
    //   ttoken: "komunalni_obveznici.namjena_prije",
    //   type: "text",
    //   search: false
    // },
    {
      title: 'Odstupanje',
      source: 'odstupanje',
      ttoken: 'ena:komunalni_obveznici.odstupanje',
      type: 'picker',
      items: {
        labels: [
          'ena:komunalni_obveznici.blizu_originala',
          'ena:komunalni_obveznici.povecanje',
          'ena:komunalni_obveznici.smanjenje',
          '',
        ],
        values: [0, 1, -1, 2],
        icons: [
          'horizontal_rule',
          'keyboard_double_arrow_up',
          'keyboard_double_arrow_down',
          '',
        ],
        iconColors: ['#2edaff', '#00b000', '#ff0000', 'rgba(256,256,256,0)'],
      },
      filter: true,
    },
    coreFields.gid,
    coreFields.docs,
    {
      ...buttonFields.download,
      action: 'download_zapisnik',
      idref: 'id',
    },
    buttonFields.show_photos,
    commonFields.wkt,
    { ...coreFields.active, filter: true },
    coreFields.created_on,
    coreFields.modified_on,
  ],
};

export default KomunalniObveznici;
