import React, { ReactElement } from 'react';

// MUI
import Avatar, { AvatarProps } from '@mui/material/Avatar';

// Types
export interface IMapCardAvatar extends AvatarProps {
  icon: ReactElement;
  ariaLabel?: string;
}

const MapCardAvatar = (props: IMapCardAvatar) => {
  const { icon, ariaLabel } = props;

  const { className } = icon.props;

  return (
    <Avatar
      aria-label={ariaLabel}
      sx={{
        '.MuiAvatar-root': {
          backgroundColor: 'common.white',
        },
      }}
    >
      <icon.type className={className || ''} sx={{ color: 'primary.main' }} />
    </Avatar>
  );
};

export default MapCardAvatar;
