import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const ThreeDSettings: IModel = {
  title: '3D Settings',
  apiPath: 'system/3d_settings',
  forms: {
    insert: {
      fields: [{ source: 'tenant_id', readonly: false }, 'cesium_folder', 'cesium_config_file'],
    },
    update: {
      fields: ['id', { source: 'tenant_id', readonly: false }, 'cesium_folder', 'cesium_config_file'],
    },
    search: {
      fields: ['cesium_folder', 'cesium_config_file', 'tenant_id'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'tenant_id', 'cesium_folder', 'cesium_config_file'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'cesium_folder',
      source: 'cesium_folder',
      ttoken: 'admin:3d.cesium_folder',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 100,
      },
    },
    {
      title: 'cesium_config_file',
      source: 'cesium_config_file',
      ttoken: 'admin:3d.cesium_config_file',
      type: 'text',
      filter: true,
      validation: {
        required: true,
        maxLength: 100,
      },
    },
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'picker',
      subModel: {
        apiPath: 'system/tenants?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      validation: {
        required: true,
      },
      filter: true
    },
  ],
};

export default ThreeDSettings;
