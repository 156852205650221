import { IModel } from '@/@types/models/model';
import commonFields from './helpers/commonFields';

const DKP: IModel = {
  title: 'DKP',
  apiPath: 'data/dkp',
  forms: {
    default: {
      fields: ['ko_mb', 'ko_ime', 'kc_broj'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'ko_mb', 'kc_broj', 'ko_ime', 'show_on_map'],
    },
  },
  fields: [
    {
      title: 'id',
      source: 'id',
      ttoken: 'common.id',
      type: 'numeric',
      idattr: true,
      readonly: true,
      // search: false,
    },
    {
      title: 'ko_mb',
      source: 'ko_mb',
      ttoken: 'dkp.ko_mb',
      type: 'text',
      readonly: true,
    },
    {
      title: 'ko_ime',
      source: 'ko_ime',
      ttoken: 'dkp.ko_ime',
      type: 'text',
      readonly: true,
    },
    {
      title: 'kc_broj',
      source: 'kc_broj',
      ttoken: 'dkp.kc_broj',
      type: 'text',
      readonly: true,
    },
    {
      title: '',
      source: 'show_on_map',
      tooltip: 'buttons.show_on_map',
      ttoken: '',
      type: 'button',
      icon: 'fmd_good',
      action: 'show_on_map',
      padding: 'none',
      sort: false,
      // search: false,
    },
    commonFields.wkt,
    commonFields.proj,
  ],
};

export default DKP;
