/* eslint-disable */
import React, {
  useContext,
  useState,
  useEffect,
  FunctionComponent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Ui
import ModelTable from '@/ui/Table/ModelTable';
import TableButtonView from '@/ui/Table/ActionButtons/TableButtonView';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import TableButtonShowPhotos from '@/ui/Table/ActionButtons/TableButtonShowPhotos';
import TableButtonDocuments from "@/ui/Table/ActionButtons/TableButtonDocuments";
import { DialogFormGenerateFunction } from '@/ui/MapCentric/MapCentricPage';

// Components
import useDocuments from '@/components/useDocuments';

// Context
import DialogContext, {
  DialogContextType,
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import LoaderContext, {
  LoaderContextType,
} from '@/context/LoaderContext/LoaderContext';
import SnackbarContext, {
  SnackbarContextType,
} from '@/context/SnackbarContext/SnackbarContext';

// Lib
import DataController, { IDataController } from '@/lib/DataController';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { IModel } from '@/@types/models/model';
import { ClosingDetails } from '@/@types/components/formController';

interface IMapCentricPrimaryTableProps {
  onLoad?: (records: Array<DCRecord>) => void;
  onRecordSelect: (id: number) => void;
  onRowAction: (action: string, id: number, record?: DCRecord) => void;

  setAllowImages: () => void;
  imagesEnabled: boolean;

  selectedRecordId?: number;
  view: string | undefined;

  model: IModel;
  titleToken: string;

  allowAdd?: boolean;
  allowView?: boolean;
  allowEdit?: boolean;
  dialogComponent?: React.FunctionComponent<IDialogProps>;
  dialogForm?: string | DialogFormGenerateFunction;
  dialogAsPagePath?: string | undefined;
}

const MapCentricPrimaryTable: FunctionComponent<
  React.PropsWithChildren<IMapCentricPrimaryTableProps>
> = (props) => {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const { checkIfHasGid, showDocumentsDialog } = useDocuments();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { onLoad, onRowAction, setAllowImages, onRecordSelect } = props;
  const { model, titleToken, view, imagesEnabled, selectedRecordId } = props;
  const {
    allowAdd,
    allowView,
    allowEdit,
    dialogComponent,
    dialogForm,
    dialogAsPagePath,
  } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecords(resp.data);
          if (resp.data.length === 0) {
            snackbarContext.showNotification(t('messages.nodata'), 'warning');
          }
        }
      })
      .catch((resp) => {
        console.log(resp);
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
        if (onLoad) onLoad(records);
      });
  };

  const dc: IDataController = new DataController(model);

  const handleView = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    const form =
      typeof dialogForm === 'function' ? dialogForm(record) : dialogForm;

    if (dialogComponent && record?.id && dialogAsPagePath === undefined) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'view',
        form,
        recordId: record.id,
        onClose: handleDialogClose
      });
    } else if (
      record !== undefined &&
      record?.id &&
      dialogAsPagePath !== undefined
    ) {
      navigate(dialogAsPagePath + String(record.id), {
        state: { view: 'view', form },
      });
    }
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    const form =
      typeof dialogForm === 'function' ? dialogForm(record) : dialogForm;

    if (dialogComponent && record?.id && dialogAsPagePath === undefined) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'update',
        form,
        initialRecord: record,
        onClose: handleDialogClose
      });
    } else if (
      record !== undefined &&
      record?.id &&
      dialogAsPagePath !== undefined
    ) {
      navigate(dialogAsPagePath + String(record.id), {
        state: { view: 'update', form },
      });
    }
  };

  const handleDocuments = ( evt: React.SyntheticEvent, sel: { [key: string]: DCRecord }) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];
    const gid = record.gid as string;

    if (gid) {
      showDocumentsDialog(gid, handleDialogClose);
    }
  }

  const handleAdd = () => {
    if (dialogComponent) {
      dialogContext.showDialog(dialogComponent, {
        dc,
        mode: 'insert',
        form: 'insert',
        onClose: handleDialogClose
      });
    }
  };

  const handleDialogClose = (result: ClosingDetails) => {
    if (result.dataChanged) {
      refreshRecords();
    }
  };

  const hasGid = checkIfHasGid(records);

  return (
    <ModelTable
      title={t(titleToken)}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter
      allowExport={false}
      allowAdd={allowAdd}
      handleAdd={handleAdd}
      addLabel={`${t('buttons.new_f')}`}
      onRowAction={onRowAction}
      onRowClick={(
        evt: React.SyntheticEvent,
        sel: { [key: string]: DCRecord }
      ) => {
        const clickedRecord = sel[Object.keys(sel)[0]];
        return onRecordSelect(clickedRecord.id as number);
      }}
      allowRowAction={() => true}
      viewName={view}
      rowSelectedCustom={selectedRecordId}
    >
      { hasGid ?
        <TableButtonDocuments onClick={handleDocuments} />
      : <></>
      }
      <TableButtonShowPhotos
        disabled={!imagesEnabled}
        variant="outlined"
        onClick={setAllowImages}
      />
      {allowEdit ? (
        <TableButtonEdit variant="outlined" onClick={handleEdit} />
      ) : (
        <></>
      )}
      {allowView ? (
        <TableButtonView variant="contained" onClick={handleView} />
      ) : (
        <></>
      )}
    </ModelTable>
  );
};

export default MapCentricPrimaryTable;
