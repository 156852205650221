/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AppValidator from '@/lib/AppValidator';
import DialogContext from '@/context/DialogContext/DialogContext';

import { authService } from '@/services/authService';

// Types
import {
  AuthFormControllerProps,
  ServerErrorPromise,
  DoLoginResponse,
  DoLoginResponseSuccess,
  DoLoginResponseFail,
  DoRegisterResponseFail,
  DoRegisterResponseSuccess,
  DoActivateResponseFail,
  DoActivateResponseSuccess,
  DoRequestResetPasswordSuccess,
  DoRequestResetPasswordFail,
  DoChangePasswordResponseSuccess,
  DoChangePasswordResponseFail,
  DoResetPasswordResponseSuccess,
  DoResetPasswordResponseFail,
  DoActivateResponse,
  DoRequestResetPassword,
  DoChangePasswordResponse,
  DoRegisterResponse,
  DoResetPasswordResponse,
} from '@/@types/components/authFormController';
import { IFieldsCollection } from '@/@types/models/model';
import { DCFieldValue, DCRecord } from '@/@types/lib/dataController';
import { ConfirmResult } from '@/ui/Dialog/ConfirmDialog/ConfirmDialog';
import { RecordValidation, ValidatorFnName } from '@/@types/lib/appValidator';
import { LoginData } from '@/@types/lib/api';
import { ActivateData } from '@/@types/services/authService';

function useAuthFormController(props: AuthFormControllerProps) {
  const dialogContext = useContext(DialogContext);

  const [validation, setValidation] = useState<RecordValidation>({});
  const [validated, setValidated] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [record, setRecord] = useState<DCRecord>({});
  const [isLoading, setIsLoading] = useState(false);

  const [lastChangedField, setLastChangedField] = useState<string>();
  const [lastChangeAt, setLastChangeAt] = useState<Date>();

  const [originalRecord, setOriginalRecord] = useState<DCRecord>({});

  const { t } = useTranslation();

  const validator = new AppValidator(t);

  const { dc, form, mode, initialRecord, controllerForm } = props;

  const formId = controllerForm || form || mode;
  const fields: IFieldsCollection = dc.getFormFields(formId);

  let mounted = false;

  useEffect(() => {
    mounted = true;

    if (initialRecord !== undefined) {
      setRecord({ ...initialRecord });
      setOriginalRecord({ ...initialRecord });
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (validated && lastChangedField !== undefined) {
      validateField(lastChangedField);
    }
    setDataChanged(checkIfChanged(record));
  }, [lastChangeAt, lastChangedField]);

  const checkIfChanged = (currentRecord: DCRecord) => {
    const changed = validator.checkIfRecordChanged(
      currentRecord,
      originalRecord
    );
    return changed;
  };

  const validateField = (source: string) => {
    const field = fields.find((f) => f.source === source);

    if (field !== undefined) {
      const fieldValidation = validator.validateField(record, field);

      setValidation((prevState) => ({
        ...prevState,
        [source]: fieldValidation,
      }));
    }
  };

  const getValidator = () => {
    const formId = form || mode;
    const validateFunc = dc.getValidator(formId) as ValidatorFnName;
    if (validateFunc !== null && validateFunc !== 't') {
      if (
        validator[validateFunc] !== undefined &&
        typeof validator[validateFunc] === 'function'
      ) {
        return validator[validateFunc];
      } /* else {
        // return (record: any, t: any) => ({});
      } */
    } /* else {
      // return (record: any, t: any) => ({});
    } */
    return null;
  };

  const validate = () => {
    // model validation
    const validation = validator.validateModel(record, fields);

    // custom validation
    const validatorFn = getValidator();
    const customValidation = validatorFn !== null ? validatorFn(record, t) : {};

    const finalValidation = validator.mergeValidation(
      validation,
      customValidation
    );
    const isValid = validator.checkIfValid(finalValidation);

    setValidation(finalValidation);
    setValidated(true);

    return isValid;
  };

  const serverErrorPromise = (
    serverValidation: RecordValidation
  ): Promise<ServerErrorPromise> => {
    setValidation(serverValidation);
    return Promise.reject({
      success: false,
      validationPass: false,
      validation: serverValidation,
    });
  };

  // *** confirmations ***

  const confirmClose = (): Promise<ConfirmResult> => {
    const title = t('cdialogs.changes');
    const text = t('cdialogs.want_to_save');
    const type = 'warning';
    const confirmButtonText = t('cdialogs.yes_save_changes');
    const cancelButtonText = t('cdialogs.no_just_close');

    return dialogContext.showConfirmDialog({
      title,
      text,
      confirmButtonText,
      cancelButtonText,
    });
  };

  const confirmRegister = (): Promise<ConfirmResult> =>
    Promise.resolve({ confirmed: true });

  // const title = t("cdialogs.register");
  // const text = t("cdialogs.please_confirm");
  // const type = "warning";
  // const confirmButtonText = t("cdialogs.register");
  // const cancelButtonText = t("cdialogs.cancel");

  // return confirmDialog(
  //   title,
  //   text,
  //   type,
  //   confirmButtonText,
  //   cancelButtonText
  // );
  // *** Export functions ***

  const onFieldChange = (value: DCFieldValue, source: string) => {
    setRecord((prevState) => ({
      ...prevState,
      [source]: value,
    }));

    setLastChangedField(source);
    setLastChangeAt(new Date());
  };

  const doClose = () => {
    if (dataChanged) {
      return confirmClose().then((result) => {
        if (result.canceled) {
          return Promise.resolve({ success: false, canceled: true });
        }
        if (result.confirmed) {
          return Promise.resolve({ success: false, shouldsave: true });
        }
        return Promise.resolve({ success: true });
      });
    }
    return Promise.resolve({ success: true });
  };

  const doLogin = (): Promise<DoLoginResponse> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation,
      });
    }

    setIsLoading(true);
    return authService
      .login(record.username as string, record.password as string)
      .then((data): Promise<DoLoginResponse> => {
        if ((data as LoginData).auth_token) {
          return Promise.resolve({
            success: true,
            ...data,
          } as unknown as DoLoginResponseSuccess); // TODO
        }
        return serverErrorPromise({
          username: {
            valid: false,
            msg: t('login.invalid'),
          },
          password: {
            valid: false,
            msg: t('login.invalid'),
          },
        }) as Promise<DoLoginResponseFail>;
      })
      .catch((data): Promise<DoLoginResponseFail> => {
        return serverErrorPromise({
          username: {
            valid: false,
            msg: t('login.invalid'),
          },
          password: {
            valid: false,
            msg: t('login.invalid'),
          },
        }) as Promise<DoLoginResponseFail>;
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  };

  const doRegister = (): Promise<DoRegisterResponse> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation,
      } as DoRegisterResponseFail);
    }

    return confirmRegister().then((result): Promise<DoRegisterResponse> => {
      if (result.canceled || result.confirmed === false) {
        return Promise.reject({
          success: false,
          canceled: true,
        } as DoRegisterResponseFail);
      }
      if (result.confirmed) {
        setIsLoading(true);
        return authService
          .register(record)
          .then(() =>
            Promise.resolve({
              success: true,
            } as DoRegisterResponseSuccess)
          )
          .catch((data): Promise<DoRegisterResponseFail> => {
            switch (data.errorCode) {
              case 401:
                return serverErrorPromise({
                  email: {
                    valid: false,
                    msg: t('register.emailInUse'),
                  },
                }) as Promise<DoRegisterResponseFail>;
              case 402:
                return serverErrorPromise({
                  oib: {
                    valid: false,
                    msg: t('register.oibInUse'),
                  },
                }) as Promise<DoRegisterResponseFail>;
              default:
                break;
            }
            return Promise.reject({
              success: false,
              error: data.error as string,
              errorCode: data.errorCode as number,
            } as DoRegisterResponseFail);
          })
          .finally(() => {
            if (mounted) {
              setIsLoading(false);
            }
          });
      }
      if (result.confirmed === false) {
        return Promise.resolve({
          success: false,
          canceled: true,
        } as DoRegisterResponseFail);
      }
      return Promise.resolve({ success: false } as DoRegisterResponseFail);
    });
  };

  const doActivate = (): Promise<DoActivateResponse> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation,
      });
    }

    setIsLoading(true);
    return authService
      .activate(record)
      .then((data): Promise<DoActivateResponse> => {
        if (
          Object.prototype.hasOwnProperty.call(
            data as ActivateData,
            'auth_token'
          )
        ) {
          return Promise.resolve({
            success: true,
            ...data,
          } as DoActivateResponseSuccess);
        }
        return Promise.reject({
          success: false,
          error: 'Unknown error',
        } as DoActivateResponseFail);
      })
      .catch((data): Promise<DoActivateResponseFail> => {
        switch (data.errorCode) {
          case 409:
            return serverErrorPromise({
              password: { valid: false, msg: t('user.passwordReset') },
            });
          default:
            break;
        }
        return Promise.resolve({
          success: false,
          error: data.error,
          errorCode: data.errorCode,
        } as DoActivateResponseFail);
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  };

  const doSendPasswordResetRequest = (): Promise<DoRequestResetPassword> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation,
      });
    }

    setIsLoading(true);
    return authService
      .requestResetPassword(record)
      .then((result): Promise<DoRequestResetPasswordSuccess> => {
        return Promise.resolve({
          success: result.success,
        } as DoRequestResetPasswordSuccess);
      })
      .catch(
        (): Promise<DoRequestResetPasswordFail> =>
          Promise.reject({ success: false } as DoRequestResetPasswordFail)
      )
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  };

  const doResetPassword = (): Promise<DoResetPasswordResponse> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation,
      });
    }

    setIsLoading(true);
    return authService
      .resetPassword(record)
      .then((data) =>
        Promise.resolve({
          success: true,
          user: data,
        } as DoResetPasswordResponseSuccess)
      )
      .catch(() =>
        Promise.reject({
          success: false,
          validationPass: true,
        } as DoResetPasswordResponseFail)
      )
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  };

  const doChangePassword = (): Promise<DoChangePasswordResponse> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation,
      });
    }

    setIsLoading(true);
    return authService
      .changePassword(record)
      .then(
        (): Promise<DoChangePasswordResponseSuccess> =>
          Promise.resolve({ success: true })
      )
      .catch((data): Promise<DoChangePasswordResponseFail> => {
        switch (data.errorCode) {
          case 409:
            return serverErrorPromise({
              password: { valid: false, msg: t('user.passwordReset') },
            });
          default:
            break;
        }
        return Promise.resolve({
          success: false,
          validationPass: true,
          error: data.error,
          errorCode: data.errorCode,
        });
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  };

  return {
    validation,
    validated,
    dataChanged,
    fields,
    record,
    isLoading,

    onFieldChange,

    doClose,
    doLogin,
    doRegister,
    doActivate,
    doChangePassword,
    doResetPassword,
    doSendPasswordResetRequest,
  };
}

export default useAuthFormController;
