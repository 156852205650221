import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Box, useMediaQuery, useTheme } from '@mui/material';

// OpenLayers
import proj4 from 'proj4';
import { register as OlRegister } from 'ol/proj/proj4';
import { get as OlGetProjection, Projection } from 'ol/proj';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import OlBaseLayer from 'ol/layer/Base';
import OlCollection from 'ol/Collection';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFormatWKT from 'ol/format/WKT';
import OlFeature from 'ol/Feature';

// Custom
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';
import Map from '@/components/Map/Map';
import {
  Controls,
  RotateControl,
  ScaleLineControl,
  ZoomControl,
  ZoomToExtentControl,
} from '@/components/Map/Controls';
import { Layers, VectorLayer } from '@/components/Map/Layers';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import { padExtent } from '@/lib/olHelpers';
import DataController from '@/lib/DataController';
import {
  selectedStyle,
  hoveringStyle,
  workingStyle,
} from '@/components/Map/mapStyles';
import GeoAPILayers from '@/components/Map/Layers/GEO/GeoAPILayers';
import MapButton from '@/components/Map/Controls/Custom/MapButton';

// Services
import mapService from '@/services/mapService';
import gsService from '@/services/gsService';

// Types
import { IModel } from '@/@types/models/model';
import { DCRecord } from '@/@types/lib/dataController';
import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';

interface IEnaDataCentricMiniMapProps {
  lokacijaId: number;
  selectedObjektId: number;
  onObjektSelect: Function;
  onLokacijaSelect: Function;
  setParentObjExtent: (objExtent: number[]) => void;

  model: IModel;
  mapId: number;
  baseRecordPath: string;
  geomRelativePath: string;
  locationField: string;
  onResize?: () => void;
  isResized?: boolean;
}

const EnaDataCentricMiniMap = (props: IEnaDataCentricMiniMapProps) => {
  const tenantContext = useContext(TenantContext) as TenantContextType;
  const { t } = useTranslation();

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const [mapInitialized, setMapInitialized] = useState(false);

  const {
    lokacijaId,
    selectedObjektId,
    onObjektSelect,
    onLokacijaSelect,
    setParentObjExtent,
  } = props;
  const {
    model,
    mapId,
    baseRecordPath,
    geomRelativePath,
    locationField,
    onResize,
    isResized,
  } = props;

  const defaultExtent = useMemo(
    () =>
      padExtent(
        tenantContext?.mapSettings?.default_extent
          ? tenantContext?.mapSettings?.default_extent
          : [1437016, 5271097, 2206278, 5860579]
      ),
    []
  );

  const [selectedExtent, setSelectedExtent] = useState(
    padExtent(defaultExtent)
  );
  const [objExtent, setObjExtent] = useState<number[]>([]);
  const [viewOptions, setViewOptions] = useState({
    center: tenantContext?.mapSettings?.initial_view_center
      ? tenantContext.mapSettings.initial_view_center
      : ([1731757, 5581737] as [number, number]),
    extent: tenantContext?.mapSettings?.max_extent
      ? tenantContext.mapSettings.max_extent
      : [1437016, 5271097, 2206278, 5860579],
    zoom: tenantContext?.mapSettings?.initial_view_zoom
      ? tenantContext.mapSettings.initial_view_zoom
      : 8,
    minZoom: tenantContext?.mapSettings?.map_min_view_zoom
      ? tenantContext.mapSettings.map_min_view_zoom
      : 8,
    maxZoom: tenantContext?.mapSettings?.map_max_view_zoom
      ? tenantContext.mapSettings.map_max_view_zoom
      : 21,
  });
  const [layersCollection, setLayersCollection] = useState<
    OlCollection<OlBaseLayer> | undefined
  >(undefined);
  const [workingSource, setWorkingSource] = useState<OlSourceVector<Geometry>>(
    new OlSourceVector({})
  );
  const [selectedSource, setSelectedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [highlightedSource, setHighlightedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [showDOFLayer, setShowDOFLayer] = useState(true);
  const [showObjektiLayer, setShowObjektiLayer] = useState(true);

  const [hiddenLayerIDs, setHiddenLayerIDs] = useState<string[]>([]);

  let highlightedFeature: OlFeature<Geometry> | null = null;

  // define proj
  proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection('EPSG:3765');
  const wgs84PM = OlGetProjection('EPSG:3857');

  const wkt = new OlFormatWKT();

  const dc = new DataController(model);

  const allowDOF = useMemo(() => {
    // if at least one layer has show_dof functionality allow the button
    if (layersCollection && layersCollection.getLength() > 0) {
      return layersCollection
        .getArray()
        .some(
          (layer) =>
            layer.get('layer_functionality_id') ===
            EnumLayerFunctionality.toggle_dof
        );
    }

    return false;
  }, [layersCollection]);

  const allowObjekti = useMemo(() => {
    // if at least one layer has show_objekti functionality allow the button
    if (layersCollection && layersCollection.getLength() > 0) {
      return layersCollection
        .getArray()
        .some(
          (layer) =>
            layer.get('layer_functionality_id') ===
            EnumLayerFunctionality.toggle_objekti
        );
    }

    return false;
  }, [layersCollection]);

  useEffect(() => {
    // getDefaultData();
    getLayers();
  }, []);

  useEffect(() => {
    if (lokacijaId > 0) {
      refreshObjekti(lokacijaId);
    } else {
      setWorkingSource(new OlSourceVector({}));
      setObjExtent(defaultExtent);
      setViewOptions((prevState) => ({
        ...prevState,
        center: tenantContext?.mapSettings
          ? tenantContext?.mapSettings?.initial_view_center
          : [1731757, 5581737],
        zoom: tenantContext?.mapSettings
          ? tenantContext.mapSettings.initial_view_zoom
          : 8,
      }));
    }
    setSelectedSource(new OlSourceVector({}));
    setHighlightedSource(new OlSourceVector({}));
  }, [lokacijaId]);

  useEffect(() => {
    const src = new OlSourceVector({});
    const srcLoc = new OlSourceVector({});

    if (lokacijaId > 0) {
      const locFeatures = workingSource
        .getFeatures()
        .filter((x) => x.get(locationField) === lokacijaId);
      if (Array.isArray(locFeatures) && locFeatures.length > 0) {
        srcLoc.addFeatures(locFeatures);
      }
    }

    if (selectedObjektId > 0) {
      const ft: OlFeature<Geometry> | undefined = workingSource
        .getFeatures()
        .find((x) => x.getId() === selectedObjektId);
      if (ft) {
        src.addFeature(ft);
      }
    }

    setSelectedSource(src);

    // Validate the extent before setting it
    const extent = srcLoc.getExtent();
    const isValidExtent = extent.every(
      (val) => val !== Infinity && val !== -Infinity
    );

    if (isValidExtent) {
      setObjExtent(padExtent(extent));
      setParentObjExtent(extent); // Callback to parent component
    }
  }, [selectedObjektId, lokacijaId, workingSource]);

  const getLayers = () => {
    mapService.getLayers(mapId).then((coll: any) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const refreshObjekti = (lokId: number) => {
    // objektiService.getObjekti(lokacijaId).then((resp) => {
    dc.GetData(`${baseRecordPath}/${lokId}/${geomRelativePath}`).then(
      (resp) => {
        if (resp.success) {
          const src = new OlSourceVector({});
          const records = resp.data as DCRecord[];
          if (Array.isArray(records) && records.length > 0) {
            records.forEach((record: DCRecord) => {
              if (record.wkt) {
                const newFeature = new OlFeature({
                  geometry: wkt.readGeometry(record.wkt, {
                    dataProjection: htrs96 as Projection,
                    featureProjection: wgs84PM as Projection,
                  }),
                });
                newFeature.setId(record.id as number);
                newFeature.setProperties({
                  type: model.source,
                  id: record.id,
                  [locationField]: lokacijaId,
                });
                // console.log(newFeature);
                src.addFeature(newFeature);
              }
            });
            setWorkingSource(src);
            setSelectedExtent(padExtent(src.getExtent()));
            setObjExtent([]);
          } else {
            setWorkingSource(new OlSourceVector({}));
            setSelectedExtent(padExtent(defaultExtent));
            setObjExtent(defaultExtent);
          }
        }
      }
    );
  };

  // const getDefaultData = () => {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       setDefaultExtent(viewData.default_extent);
  //       setSelectedExtent(viewData.default_extent);
  //       setViewOptions((prevViewOptions) => ({
  //         ...prevViewOptions,
  //         ...{
  //           zoom: 14,
  //           center: viewData.initial_view_center
  //         }
  //       }));
  //       // this.setState((prevState) => {
  //       //   return {
  //       //     ...prevState,
  //       //     viewOptions: {
  //       //       ...prevState.viewOptions,
  //       //       zoom: viewData.initial_view_zoom,
  //       //       center: viewData.initial_view_center
  //       //     },
  //       //     zoomToExtent: viewData.default_extent,
  //       //     defaultExtent: viewData.default_extent
  //       //   };
  //       // });
  //     }
  //   });
  // };

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      (foundFeature) => foundFeature,
      {
        layerFilter: (layer) => {
          const layerId = layer.get('id');
          return (
            layerId !== null &&
            layerId !== undefined &&
            layerId.startsWith('objekti')
          );
        },
      }
    );

    if (feature) {
      const id = feature.getId();
      onLokacijaSelect(id);
    } else if (showObjektiLayer) {
      const layer = layersCollection
        ?.getArray()
        .find((x) => x.get('id') === 'objekti');

      if (layer) {
        gsService.getFeatureInfo(evt.map, evt.pixel, [layer]).then((resp) => {
          if (resp && Object.keys(resp).length !== 0) {
            const layerKeys = Object.keys(resp);
            const key = layerKeys.find((x) => x.substring(4) === model.source);
            const features = key ? resp[key] : [];

            if (Array.isArray(features) && features.length > 0) {
              const obj = features[0];
              const objid = obj.properties.id;
              const locId = obj.properties[locationField];
              if (locId === lokacijaId) {
                if (objid) {
                  onObjektSelect(objid);
                }
              } else {
                onLokacijaSelect(locId, obj.properties);
              }
            } else {
              onLokacijaSelect(null);
            }
          } else {
            onLokacijaSelect(null);
          }
        });
      } else {
        onLokacijaSelect(null);
      }
    } else {
      onLokacijaSelect(null);
    }
  };

  const handlePointerMove = (evt: OlMapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    const pixel = evt.map.getEventPixel(evt.originalEvent);
    const feature = evt.map.forEachFeatureAtPixel(
      pixel,
      (foundFeature: any) => foundFeature,
      {
        layerFilter: (layer) => {
          const layerId = layer.get('id');
          return (
            layerId !== null &&
            layerId !== undefined &&
            layerId.startsWith('objekti')
          );
        },
      }
    ) as unknown as OlFeature<Geometry>;
    highlightFeature(feature);
  };

  const handleLayerSwitcherDOFClick = () => {
    setShowDOFLayer((prevState) => !prevState);
  };

  const handleLayerSwitcherObjektiClick = () => {
    setShowObjektiLayer((prevState) => !prevState);
  };

  useEffect(() => {
    const hiddenLayerIDsNew: string[] = [];
    if (!showDOFLayer) hiddenLayerIDsNew.push('dof');
    if (!showObjektiLayer) hiddenLayerIDsNew.push('objekti');

    setHiddenLayerIDs(hiddenLayerIDsNew);
  }, [showDOFLayer, showObjektiLayer]);

  const highlightFeature = (feature: OlFeature<Geometry>) => {
    const olFeature = feature;
    if (olFeature !== highlightedFeature) {
      if (highlightedSource) {
        if (highlightedFeature) {
          highlightedSource.removeFeature(highlightedFeature);
        }
        if (olFeature) {
          highlightedSource.addFeature(olFeature);
        }
      }
      highlightedFeature = olFeature;
    }
  };

  const handleClickResize = () => {
    if (onResize) {
      onResize();
    }
  };

  return layersCollection ? (
    <Map
      height="500px"
      view={viewOptions}
      onClick={handleClick}
      onPointermove={handlePointerMove}
      className="map"
      id="mini-map"
      zoomToExtent={
        objExtent &&
        objExtent.length &&
        objExtent.every((val) => val !== Infinity && val !== -Infinity)
          ? objExtent
          : selectedExtent || undefined
      }
      initialized={mapInitialized}
    >
      <Controls>
        <ZoomControl
          zoomInTipLabel={t('map:controls.zoom_in') as string}
          zoomOutTipLabel={t('map:controls.zoom_out') as string}
        />
        <RotateControl autoHide={false} />
        <ScaleLineControl type="minimap" />
        <GeoBaseLayerSwitcher type="minimap" />
        <ZoomToExtentControl
          id="zoom-extent-default"
          extent={defaultExtent}
          tipLabel={t('map:controls.zoom_to_extent') as string}
          className="ol-control ol-zoom-extent"
        />
        <ZoomToExtentControl
          id="zoom-extent-selected"
          extent={selectedExtent}
          tipLabel={t('map:controls.zoom_to_selected') as string}
          className="ol-control ol-zoom-selected"
          // labelClass="fas fa-bullseye"
        />
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {allowDOF && (
            <MapButton
              id="layerswitcher-dof"
              className="ol-layerswitcher-dof"
              handleClick={handleLayerSwitcherDOFClick}
              title={t('map:layerswitcher.toggle_dof') as string}
              active={showDOFLayer}
            >
              <i className="fas fa-camera" />
            </MapButton>
          )}
          {allowObjekti && (
            <MapButton
              id="layerswitcher-objekti"
              className="ol-layerswitcher-objekti"
              handleClick={handleLayerSwitcherObjektiClick}
              title={t('map:layerswitcher.toggle_objekti') as string}
              active={showObjektiLayer}
            >
              <i className="fas fa-vector-square" />
            </MapButton>
          )}
          {onResize && mdUp ? (
            <MapButton
              id="button-resize"
              className="ol-button-resize"
              handleClick={handleClickResize}
            >
              <i
                className={
                  isResized
                    ? 'fas fa-angle-double-down'
                    : 'fas fa-angle-double-up'
                }
              />
            </MapButton>
          ) : null}
        </Box>
      </Controls>
      <Layers>
        {/* {layersCollection ? (
          <>
            {layersCollection
              .getArray()
              .map((layer: OlBaseLayer, i: number) => {
                const id = layer.get('id');
                const visible =
                  id === 'app-dof'
                    ? showDOFLayer
                    : id === 'app-objekti'
                    ? showObjektiLayer
                    : true;

                return (
                  <GeoPortalGSLayer
                    key={i}
                    id={id}
                    title={t(layer.get('title'))}
                    layer={layer}
                    query={layer.get('query')}
                    zIndex={layer.get('zIndex')}
                    visible={visible}
                  />
                );
              })}
          </>
        ) : null} */}
        <GeoAPILayers
          layersCollection={layersCollection}
          hiddenIDs={hiddenLayerIDs}
        />
        <VectorLayer
          id="_working"
          source={workingSource}
          style={workingStyle}
          zIndex={900}
        />
        <VectorLayer
          id="_selected"
          source={selectedSource}
          style={selectedStyle}
          zIndex={950}
        />
        <VectorLayer
          id="_highlighted"
          source={highlightedSource}
          style={hoveringStyle}
          zIndex={960}
        />
      </Layers>
    </Map>
  ) : null;
};

export default EnaDataCentricMiniMap;
