/* eslint-disable */
import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';

const GrobnaMjesta: IModel = {
  title: 'Grobna mjesta',
  apiPath: 'groblja/grobna-mjesta',
  forms: {
    default: {
      fields: [
        'groblje_id',
        'odjel',
        'polje',
        'red',
        'broj',
        'polozaj',
        'vrsta_id',
        'status_id',
        'knjiga',
        'knjiga_strana',
        'is_spomenik',
        'vlasnik',
        'vlasnik_adresa',
        'vlasnik_kontakt',
        'vlasnik_oib',
        'napomena',
      ],
    },
    update: {
      fields: [
        'groblje_id',
        'odjel',
        'polje',
        'red',
        'broj',
        'polozaj',
        'vrsta_id',
        'status_id',
        'knjiga',
        'knjiga_strana',
        'is_spomenik',
        'vlasnik_ime',
        'vlasnik_prezime',
        'vlasnik_adresa',
        'vlasnik_kontakt',
        'vlasnik_oib',
        'napomena',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'groblje_id',
        'odjel',
        'polje',
        'red',
        'broj',
        'polozaj',
        'vrsta_id',
        'status_id',
        'knjiga',
        'knjiga_strana',
        'is_spomenik',
        'vlasnik',
        'vlasnik_adresa',
        'vlasnik_kontakt',
        'vlasnik_oib',
        'napomena',
        'docs',
      ],
      hidden: ['id', 'knjiga', 'knjiga_strana'],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'groblje_id',
      source: 'groblje_id',
      ttoken: 'groblja:grobna_mjesta.groblje',
      type: 'picker',
      subModel: {
        apiPath: 'groblja/?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      validation: {
        required: true,
      },
      filter: true,
    },
    {
      title: 'odjel',
      source: 'odjel',
      ttoken: 'groblja:grobna_mjesta.odjel',
      type: 'text',
      filter: true,
    },
    {
      title: 'polje',
      source: 'polje',
      ttoken: 'groblja:grobna_mjesta.polje',
      type: 'text',
      filter: true,
    },
    {
      title: 'red',
      source: 'red',
      ttoken: 'groblja:grobna_mjesta.red',
      type: 'text',
      filter: true,
    },
    {
      title: 'broj',
      source: 'broj',
      ttoken: 'groblja:grobna_mjesta.broj',
      type: 'text',
      filter: true,
    },
    {
      title: 'polozaj',
      source: 'polozaj',
      ttoken: 'groblja:grobna_mjesta.polozaj',
      type: 'text',
      filter: true,
    },
    {
      title: 'vrsta_id',
      source: 'vrsta_id',
      ttoken: 'groblja:grobna_mjesta.vrsta',
      type: 'picker',
      subModel: {
        apiPath: 'groblja/grobna-mjesta/vrste',
        format: '{label}',
        idattr: 'id',
      },
      filter: true,
    },
    {
      title: 'status_id',
      source: 'status_id',
      ttoken: 'groblja:grobna_mjesta.status',
      type: 'picker',
      subModel: {
        apiPath: 'groblja/grobna-mjesta/statusi',
        format: '{label}',
        idattr: 'id',
      },
      filter: true,
    },
    {
      title: 'knjiga',
      source: 'knjiga',
      ttoken: 'groblja:grobna_mjesta.knjiga',
      type: 'text',
      filter: true,
    },
    {
      title: 'knjiga_strana',
      source: 'knjiga_strana',
      ttoken: 'groblja:grobna_mjesta.knjiga_strana',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'is_spomenik',
      source: 'is_spomenik',
      ttoken: 'groblja:grobna_mjesta.spomenik',
      type: 'boolean',
      items: {
        labels: ['common.ne', 'common.da'],
        values: [false, true],
        default: false,
      },
      filter: true,
    },
    {
      title: 'vlasnik',
      source: 'vlasnik',
      ttoken: 'groblja:grobna_mjesta.vlasnik',
      type: 'text',
      filter: true,
    },
    {
      title: 'vlasnik_ime',
      source: 'vlasnik_ime',
      ttoken: 'groblja:vlasnici.ime',
      type: 'text',
      filter: true,
    },
    {
      title: 'vlasnik_prezime',
      source: 'vlasnik_prezime',
      ttoken: 'groblja:vlasnici.prezime',
      type: 'text',
      filter: true,
    },
    {
      title: 'vlasnik_adresa',
      source: 'vlasnik_adresa',
      ttoken: 'groblja:grobna_mjesta.vlasnik_adresa',
      type: 'text',
      filter: true,
    },
    {
      title: 'vlasnik_kontakt',
      source: 'vlasnik_kontakt',
      ttoken: 'groblja:grobna_mjesta.vlasnik_kontakt',
      type: 'text',
      filter: true,
    },
    {
      title: 'vlasnik_oib',
      source: 'vlasnik_oib',
      ttoken: 'groblja:grobna_mjesta.vlasnik_oib',
      type: 'text',
      validation: {
        maxLength: 11,
        minLength: 11,
      },
      filter: true,
    },
    {
      title: 'napomena',
      source: 'napomena',
      ttoken: 'common.napomena',
      type: 'text',
      filter: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
      readonly: true,
    },
    coreFields.gid,
    coreFields.docs,
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default GrobnaMjesta;
