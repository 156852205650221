import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import proj4 from 'proj4';

// MUI
import { Box, useMediaQuery, useTheme } from '@mui/material';

// OpenLayers
import { register as OlRegister } from 'ol/proj/proj4';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import OlBaseLayer from 'ol/layer/Base';
import OlLayerGroup from 'ol/layer/Group';
import OlCollection from 'ol/Collection';
import OlSourceVector from 'ol/source/Vector';
import Geometry from 'ol/geom/Geometry';
import OlFeature from 'ol/Feature';
import OlFormatWKT from 'ol/format/WKT';
import { Style } from 'ol/style';

// Custom components
import {
  Controls,
  RotateControl,
  ScaleLineControl,
  ZoomControl,
} from '@/components/Map/Controls';
import { Layers, VectorLayer } from '@/components/Map/Layers';
import GeoBaseLayerSwitcher from '@/components/Map/Controls/GEO/GeoBaseLayerSwitcher';
import GeoAPILayers from '@/components/Map/Layers/GEO/GeoAPILayers';
import { selectedStyle, commentsStyle } from '@/components/Map/mapStyles';
import Map from '@/components/Map/Map';
import MapButton from '@/components/Map/Controls/Custom/MapButton';

// Context
/* import UserContext, {
  UserContextType,
} from '@/context/UserContext/UserContext'; */
import TenantContext, {
  TenantContextType,
} from '@/context/TenantContext/TenantContext';

// Lib
import { flattenLayers, padExtent } from '@/lib/olHelpers';
import DataController from '@/lib/DataController';

// Services
import mapService from '@/services/mapService';
import gsService from '@/services/gsService';

// Types
import { IModel } from '@/@types/models/model';
import { DCRecord } from '@/@types/lib/dataController';
import { EnumLayerFunctionality } from '@/@types/services/gsServiceEnums';

interface IDataCentricMiniMapProps {
  selectedPrimaryRecordId: number;
  onPrimaryRecordSelect: Function;

  model: IModel;
  mapId: number;
  baseRecordPath: string;
  geomRelativePath: string;
  fetchGeomFromMW?: boolean;
  layerStyle?: (f: any) => Style[];

  onResize?: () => void;
  isResized?: boolean;
}

const DataCentricMiniMap = (props: IDataCentricMiniMapProps) => {
  // const userContext = useContext(UserContext) as UserContextType;
  const tenantContext = useContext(TenantContext) as TenantContextType;
  const { t } = useTranslation();

  const [mapInitialized, setMapInitialized] = useState(false);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const {
    selectedPrimaryRecordId,
    onPrimaryRecordSelect,
    onResize,
    isResized,
  } = props;
  const {
    model,
    mapId,
    baseRecordPath,
    geomRelativePath,
    fetchGeomFromMW,
    layerStyle = commentsStyle,
  } = props;

  /* const defaultExtent = useMemo(
    () => padExtent(tenantContext?.mapSettings?.default_extent),
    [tenantContext]
  ); */

  const [recordExtent, setRecordExtent] = useState<number[]>([]);

  const viewOptions = useMemo(
    () => ({
      center: tenantContext?.mapSettings?.initial_view_center
        ? tenantContext.mapSettings.initial_view_center
        : ([1731757, 5581737] as [number, number]),
      extent: tenantContext?.mapSettings?.max_extent
        ? tenantContext.mapSettings.max_extent
        : [1437016, 5271097, 2206278, 5860579],
      zoom: tenantContext?.mapSettings?.initial_view_zoom
        ? tenantContext.mapSettings.initial_view_zoom
        : 8,
      minZoom: tenantContext?.mapSettings?.map_min_view_zoom
        ? tenantContext.mapSettings.map_min_view_zoom
        : 8,
      maxZoom: tenantContext?.mapSettings?.map_max_view_zoom
        ? tenantContext.mapSettings.map_max_view_zoom
        : 21,
    }),
    [tenantContext]
  );

  const [layersCollection, setLayersCollection] = useState<
    OlCollection<OlBaseLayer> | undefined
  >(undefined);
  const [selectedSource, setSelectedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  /* const [highlightedSource, setHighlightedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({})); */
  const [primaryRecords, setPrimaryRecords] = useState<DCRecord[]>([]);

  const [showDOFLayer, setShowDOFLayer] = useState(true);

  const [hiddenLayerIDs, setHiddenLayerIDs] = useState<string[]>([]);

  // let highlightedFeature: OlFeature<Geometry> | null = null;

  // define proj
  proj4.defs(
    'EPSG:3765',
    '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
  proj4.defs(
    'EPSG:31276',
    '+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs'
  );
  proj4.defs(
    'EPSG:3857',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  );
  OlRegister(proj4);

  const dc = new DataController(model);

  const allowDOF = useMemo(() => {
    // if at least one layer has show_dof functionality allow the button
    if (layersCollection && layersCollection.getLength() > 0) {
      return layersCollection
        .getArray()
        .some(
          (layer) =>
            layer.get('layer_functionality_id') ===
            EnumLayerFunctionality.toggle_dof
        );
    }

    return false;
  }, [layersCollection]);

  useEffect(() => {
    getLayers();
    refreshPrimaryRecords();
  }, []);

  const getLayers = () => {
    mapService.getLayers(mapId).then((coll) => {
      // console.log('KolekcIja:', coll);
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const refreshPrimaryRecords = () => {
    dc.GetData(`${baseRecordPath}`).then((resp) => {
      if (resp.success) {
        const records = resp.data as DCRecord[];
        if (Array.isArray(records) && records.length > 0) {
          // filter when .geom is missing
          const filteredRecords = records.filter(
            (record) =>
              Object.hasOwn(record, 'geom') &&
              record.geom &&
              record.geom !== null
          );
          setPrimaryRecords(filteredRecords);
        } else {
          setPrimaryRecords([]);
          setRecordExtent([]);
        }
      }
    });
  };

  useEffect(() => {
    if (!fetchGeomFromMW && primaryRecords.length > 0) {
      const features = primaryRecordsSource
        ?.getFeatures()
        .filter((x) => x.getId() === selectedPrimaryRecordId);
      const srcVector = new OlSourceVector({ features });
      setSelectedSource(srcVector);
      const rec = primaryRecords.find((x) => x.id === selectedPrimaryRecordId);

      if (rec) {
        setRecordExtent(padExtent(srcVector.getExtent()) as number[]);
      } else {
        setRecordExtent([]);
      }
    } else if (selectedPrimaryRecordId > 0) {
      const url =
        `${baseRecordPath}/${selectedPrimaryRecordId}/${geomRelativePath}` as string;
      dc.GetData(url).then((resp) => {
        if (resp.success) {
          const dcRec = resp.data as DCRecord;
          const wkt = dcRec?.wkt as string;
          const proj = dcRec?.proj as string;
          if (wkt && proj) {
            const wktFormatter = new OlFormatWKT();
            const feat = wktFormatter.readFeature(wkt, {
              dataProjection: proj,
              featureProjection: 'EPSG:3857',
            });
            setSelectedSource(new OlSourceVector({ features: [feat] }));
            const geom = feat.getGeometry();
            if (geom) {
              setRecordExtent(geom.getExtent());
            }
          }
        } else {
          setRecordExtent([]);
        }
      });
    }
  }, [selectedPrimaryRecordId]);

  useEffect(() => {
    if (selectedSource) {
      const features = selectedSource.getFeatures();
      if (features.length > 0) {
        setRecordExtent(selectedSource.getExtent());
      }
    }
  }, [selectedSource]);

  const primaryRecordsSource = useMemo(() => {
    if (primaryRecords === null) return null;

    const features = primaryRecords
      .map((x) => x.geom)
      .filter((x) => x !== undefined);

    return new OlSourceVector({ features: features as OlFeature<Geometry>[] });
  }, [primaryRecords]);

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      (foundFeature) => foundFeature,
      {
        layerFilter: (layer) => {
          const layerId = layer.get('id');
          return (
            layerId !== null &&
            layerId !== undefined &&
            layerId.startsWith('primary-records')
          );
        },
      }
    );

    if (feature) {
      const id = feature.getId();
      onPrimaryRecordSelect(id);
    } else {
      const allLayers = layersCollection
        ? flattenLayers(layersCollection.getArray()).filter(
            (x) => !(x instanceof OlLayerGroup)
          )
        : [];

      const GSLayerNames = allLayers
        .filter((x) => x.get('query') === true)
        .map((x) => x.get('layer'));

      if (GSLayerNames.length > 0) {
        gsService
          .getFeatureInfo(evt.map, evt.pixel, GSLayerNames)
          .then((resp) => {
            if (resp && Object.keys(resp).length !== 0) {
              const layerKeys = Object.keys(resp);
              const key = layerKeys.length > 0 ? layerKeys[0] : null;
              const features = key ? resp[key] : [];

              if (Array.isArray(features) && features.length > 0) {
                const feat = features[0];
                const { id } = feat.properties;
                onPrimaryRecordSelect(id, feat.properties as DCRecord);
              } else {
                onPrimaryRecordSelect(null);
              }
            } else {
              onPrimaryRecordSelect(null);
            }
          });
      } else {
        onPrimaryRecordSelect(null);
      }
    }
  };

  /* const handlePointerMove = (evt: OlMapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    const pixel = evt.map.getEventPixel(evt.originalEvent);
    const feature = evt.map.forEachFeatureAtPixel(
      pixel,
      (foundFeature: any) => foundFeature,
      {
        layerFilter: (layer) =>
          // const layerId = layer.get("id");
          // return layerId !== null && layerId !== undefined && layerId.startsWith("primary-records");
          true,
      }
    ) as unknown as OlFeature<Geometry>;
    // highlightFeature(feature);
  }; */

  const handleLayerSwitcherDOFClick = () => {
    setShowDOFLayer((prevState) => !prevState);
  };

  useEffect(() => {
    const hiddenLayerIDsNew: string[] = [];
    if (!showDOFLayer) hiddenLayerIDsNew.push('dof');

    setHiddenLayerIDs(hiddenLayerIDsNew);
  }, [showDOFLayer]);

  /* const highlightFeature = (feature: OlFeature<Geometry>) => {
    const olFeature = feature;
    if (olFeature !== highlightedFeature) {
      if (highlightedSource) {
        if (highlightedFeature) {
          highlightedSource.removeFeature(highlightedFeature);
        }
        if (olFeature) {
          highlightedSource.addFeature(olFeature);
        }
      }
      highlightedFeature = olFeature;
    }
  }; */

  const handleClickResize = () => {
    if (onResize) {
      onResize();
    }
  };

  if (layersCollection)
    return (
      <Map
        height="500px"
        view={viewOptions}
        onClick={handleClick}
        // onPointermove={handlePointerMove}
        className="sidebar-map"
        id="mini-map"
        zoomToExtent={
          recordExtent && recordExtent.length ? recordExtent : undefined
        }
        zoomToExtentPadding={[20, 20, -20, -20]}
        initialized={mapInitialized}
      >
        <Controls>
          <ZoomControl
            zoomInTipLabel={`${t('map:controls.zoom_in')}`}
            zoomOutTipLabel={`${t('map:controls.zoom_out')}`}
          />
          <RotateControl autoHide={false} />
          <ScaleLineControl type="minimap" />
          <GeoBaseLayerSwitcher
            type="minimap"
            mapId={mapId}
            allowNoLayer={false}
          />
          <Box
            sx={{
              position: 'relative',
            }}
          >
            {allowDOF && (
              <MapButton
                id="layerswitcher-dof"
                className="ol-layerswitcher-dof"
                handleClick={handleLayerSwitcherDOFClick}
                title={t('map:layerswitcher.toggle_dof') as string}
                active={showDOFLayer}
              >
                <i className="fas fa-camera" />
              </MapButton>
            )}
            {onResize && mdUp ? (
              <MapButton
                id="button-resize"
                className="ol-button-resize"
                handleClick={handleClickResize}
              >
                <i
                  className={
                    isResized
                      ? 'fas fa-angle-double-down'
                      : 'fas fa-angle-double-up'
                  }
                />
              </MapButton>
            ) : null}
          </Box>
        </Controls>
        <Layers>
          <GeoAPILayers
            layersCollection={layersCollection}
            hiddenIDs={hiddenLayerIDs}
          />
          <VectorLayer
            id="primary-records"
            source={primaryRecordsSource}
            style={layerStyle}
            zIndex={900}
          />
          <VectorLayer
            id="selected"
            source={selectedSource}
            style={selectedStyle}
            zIndex={950}
          />
        </Layers>
      </Map>
    );

  return null;
};

export default DataCentricMiniMap;
