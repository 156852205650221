import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// UI
import ModelTable, { IModelTableProps } from '@/ui/Table/ModelTable';
import TableButtonDelete from '@/ui/Table/ActionButtons/TableButtonDelete';
import TableButtonEdit from '@/ui/Table/ActionButtons/TableButtonEdit';
import TableButtonView from '@/ui/Table/ActionButtons/TableButtonView';
import GeneralDialog from '@/ui/Dialog/GeneralDialog';

// Context
import DialogContext from '@/context/DialogContext/DialogContext';

// Types
import { DataResponse, DCRecord } from '@/@types/lib/dataController';
import { ClosingDetails } from '@/@types/components/authFormController';

interface IDCTable extends IModelTableProps {
  title: string;
  allowView?: boolean;
  allowAdd?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

const DCTable = (props: IDCTable) => {
  const { dc, allowView, allowEdit, allowDelete, title } = props;

  const { t } = useTranslation();

  const { showDialog, showConfirmDialog } = useContext(DialogContext);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const refreshRecords = () => {
    dc.GetData().then((resp: DataResponse) => {
      if (resp.success && Array.isArray(resp.data)) {
        setRecords(resp.data);
      }
    });
  };

  const handleView = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    if (record?.id) {
      showDialog(GeneralDialog, {
        dc,
        mode: 'view',
        initialRecord: record,
        onClose: handleDialogClose,
        title: `${t(title)} - ${t('common.view')}`,
      });
    }
  };

  const handleEdit = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const selId = parseInt(Object.keys(sel)[0], 10);
    const record: DCRecord = sel[selId];

    if (record?.id) {
      showDialog(
        GeneralDialog,
        {
          dc,
          mode: 'update',
          form: 'default',
          initialRecord: record,
          onClose: handleDialogClose,
          title: `${t(title)} - ${t('common.update')}`,
        },
        2
      );
    }
  };

  const handleAdd = () => {
    showDialog(
      GeneralDialog,
      {
        dc,
        mode: 'insert',
        form: 'insert',
        onClose: handleDialogClose,
        title: `${t(title)} - ${t('common.insert')}`,
      },
      2
    );
  };

  const handleDelete = async (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const record = sel[Object.keys(sel)[0]]; // TODO handle multiple?

    const confirmResult = await showConfirmDialog({
      title: t(title) as string,
      text: `${t('cdialogs.are_you_sure')}`,
      confirmButtonText: t('buttons.confirm') as string,
      cancelButtonText: t('buttons.cancel') as string,
    });

    if (confirmResult.confirmed && record.id) {
      await dc.DeleteRecord(record.id as number);
      refreshRecords();
    }
  };

  const handleDialogClose = (result: ClosingDetails) => {
    switch (result.action) {
      case 'insert':
      case 'update':
      case 'delete':
        if (result.dataChanged) refreshRecords(); // only refresh if something changed
        break;
      case 'cancel': // if user canceled don't refresh
      default:
        break;
    }
  };

  useEffect(() => {
    refreshRecords(); // fetch records on first render
  }, []);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ModelTable handleAdd={handleAdd} records={records} {...props}>
      {allowDelete ? (
        <TableButtonDelete variant="outlined" onClick={handleDelete} />
      ) : null}
      {allowEdit ? (
        <TableButtonEdit variant="outlined" onClick={handleEdit} />
      ) : null}
      {allowView ? (
        <TableButtonView variant="contained" onClick={handleView} />
      ) : null}
    </ModelTable>
  );
};

export default DCTable;
