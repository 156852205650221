/* eslint-disable */
import React, { useContext } from 'react';

// Custom Components
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelGrobnaMjesta from '@/models/grobna_mjesta';
import modelUkopani from '@/models/grobno_mjesto_ukopani';

// Types
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';
import UserContext from '@/context/UserContext/UserContext';
import { blackOutlineStyle } from '@/components/Map/mapStyles';

type GrobnaMjestaDataCentricPageParams = {};

const GrobnaMjestaDataCentricPage = (
  props: GrobnaMjestaDataCentricPageParams
) => { 
  const userContext = useContext(UserContext);
  const canEdit = userContext?.hasAnyPermission([Permissions.GrobljaEdit]);
  
  return (
    <AppPage
      titlett="groblja:page.grobna_mjesta"
      needPermission={[Permissions.Groblja]}
    >
      <DataCentricPage
        primaryModel={modelGrobnaMjesta}
        primaryTitleToken="titles.grobna_mjesta"
        primaryView='default'
        secondaryModel={modelUkopani}
        secondaryTitleToken="titles.ukopani"
        secondaryView='default'
        baseRecordPath={modelGrobnaMjesta.apiPath}
        childRecordRelativePath="ukopani"
        geomRelativePath=""
        mapId={41}
        mapModel={modelGrobnaMjesta}
        mapLayerStyle={blackOutlineStyle}
        primaryAllowView
        primaryAllowEdit={canEdit}
        secondaryAllowAdd={canEdit}
        secondaryAllowEdit={canEdit}
        secondaryAllowDelete={canEdit}
      />
    </AppPage>
  )}

export default GrobnaMjestaDataCentricPage;
