/* eslint-disable */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';

// Custom Components
import OlFormatWKT from 'ol/format/WKT';
import OlPoint from 'ol/geom/Point';
import OlSourceVector from 'ol/source/Vector';
import OlStyle from 'ol/style/Style';
import { Geometry } from 'ol/geom';
import ModelTable from '@/ui/Table/ModelTable';
import MapContext from '@/context/MapContext/MapContext';

import DialogContext from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
// import SidebarContext from '@/components/Map/Controls/Sidebar/SidebarContext/SidebarContext';
import { DCRecord } from '@/@types/lib/dataController';

// OpenLayers
import { fadeOutHighlightedStyle } from '../Map/mapStyles';
import { IDataController } from '@/lib/DataController';
import { Extent } from 'ol/extent';
import { Feature } from 'ol';

export type DataPaneProps = {
  tableName: string;
  viewName: string;
  dc: IDataController;
  records: DCRecord[];
  setSelectedSource: (source: OlSourceVector<Geometry>) => void;
  setStyle: (style: OlStyle) => void;
  closeOverlays: (callback?: any) => void;
};

const DataPane = (props: DataPaneProps) => {
  const { t } = useTranslation();

  const mapContext = useContext(MapContext);
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);
  // const sideBarContext = useContext(SidebarContext);

  const {
    dc,
    records,
    tableName,
    viewName,
    setSelectedSource,
    setStyle,
    closeOverlays,
  } = props;

  const allowRowAction = (action: string, id: number) => {
    switch (action) {
      case 'show_on_map':
        return true;
      default:
        return true;
    }
  };

  const handleRowAction = (action: string, id: number) => {
    switch (action) {
      case 'show_on_map': {
        closeOverlays();
        const rec = records.find((x) => x.id === id);
        if (rec) {
          let feat: any;
          if (rec.wkt) {
            const wktFormatter = new OlFormatWKT();
            feat = wktFormatter.readFeature(rec.wkt, {
              dataProjection: rec.proj ? (rec.proj as string) : 'EPSG:3857',
              featureProjection: 'EPSG:3857',
            });
          } else if (rec.geom) {
            feat = rec.geom as Feature;
          } else break;
          // console.log(feat);
          const geom = feat.getGeometry();

          if (mapContext && mapContext.map) {
            const view = mapContext.map.getView();
            setSelectedSource(new OlSourceVector({ features: [feat] }));
            if (geom instanceof OlPoint) {
              view.animate({
                center: geom.getCoordinates(),
                zoom: 20,
                duration: 500
              })
            } else {
              //@ts-ignore
              const extent = feat.getGeometry()?.getExtent();
              view.fit(extent as Extent, { padding: [20, 20, 20, 220], duration: 500});
            }
          }
          if (geom instanceof OlPoint) {
            if (mapContext && mapContext.map) {
              const view = mapContext.map.getView();
            }
          } else if (mapContext && mapContext.map) {
            const view = mapContext.map.getView();
          }
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box m={0} p={0} style={{ height: 'calc(100% - 40px)' }}>
      <ModelTable
        dc={dc}
        title={t(`titles.${tableName}`)}
        tableName={tableName}
        records={records}
        allowSelection="none"
        allowAdd={false}
        allowFilter={false}
        allowColumnPicker={false}
        // allowInc={false}
        // showControlSwitch={false}
        viewName={viewName}
        allowRowAction={allowRowAction}
        onRowAction={handleRowAction}
        // initialPageSize={Array.isArray(records) ? records.length + 1 : undefined}
        // paginationPosition="bottom"
        // smallButtons
      />
    </Box>
  );
};

export default DataPane;
