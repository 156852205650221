import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
// import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import { DialogContent } from '@mui/material';

// MUI Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Custom Components
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';

import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';

import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
// import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import ModelTable from '@/ui/Table/ModelTable';

import { DCRecord } from '@/@types/lib/dataController';
import DataController from '@/lib/DataController';
import modelFile from '@/models/file';
import modelDocuments from '@/models/documents';
import TableButtonDelete from '@/ui/Table/ActionButtons/TableButtonDelete';

const AttachedDocumentDialog = (props: IDialogProps) => {
  const {
    onClose,
    initialRecord,
    gid,
    showNewDocumentDialog,
    onAddNewDocument,
  } = props;

  const dcFile = new DataController(modelFile);
  const dcDocuments = new DataController(modelDocuments);

  const { t } = useTranslation();
  const { hideDialog, showConfirmDialog } = useContext(DialogContext);
  // const snackbarContext = useContext(SnackbarContext);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const refreshDocuments = () => {
    // WE NEED TO REFRESH COMMENTS HERE INSTEAD OF IN USECOMMENTS BECAUSE COMMENTLISTITEM IS UNMOUNTED AND CANT REFRESH COMMENTS. BUT WE STILL NEED INITIAL FETCH IN USECOMMENTS.
    (dcDocuments as DataController)
      .GetData(`${dcDocuments.apiPath}/${gid}`)
      .then((resp) => {
        if (resp.success) {
          if (resp.data && !Array.isArray(resp.data) && resp.data.files) {
            setRecords(parseFileRecords(resp.data.files as DCRecord[]));
          } else {
            setRecords([]);
          }
        } else {
          console.warn(
            'Error while fetching comments for gid:',
            gid,
            'response:',
            resp
          );
        }
      })
      .catch((error) => {
        console.warn('Error while fetching comments:', error);
      });
  };

  useEffect(() => {
    setRecords(parseFileRecords(initialRecord));
  }, []);

  const parseFileRecords = (files: DCRecord[]): DCRecord[] =>
    files.map((file) => ({
      id: file.id,
      file: [file],
      created_by: file.created_by,
      created_on: file.created_on,
      name: file.name,
    }));

  const handleAdd = () => {
    showNewDocumentDialog(gid, () => {
      onAddNewDocument();
      refreshDocuments();
    });
  };

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }

    hideDialog(1);
  };

  const handleClose = () => {
    close({ success: false });
  };

  const handleDeleteDocs = (
    evt: React.SyntheticEvent,
    selection: { [key: string]: DCRecord }
  ) => {
    showConfirmDialog({
      title: t('cdialogs.are_you_sure') as string,
      text: t('cdialogs.once_deleted') as string,
      confirmButtonText: t('cdialogs.yes_delete') as string,
      cancelButtonText: t('cdialogs.no_cancel') as string,
    }).then((result) => {
      if (result.confirmed) {
        dcDocuments
          .DeleteRecord(gid, null, {
            doc_ids: Object.keys(selection).map(
              (recKey) => selection[recKey].id
            ),
          })
          .then((resp) => {
            if ('success' in resp) {
              if (resp.success) {
                refreshDocuments();
              } else {
                console.warn('Document delete failed!');
              }
            } else {
              console.warn('Document delete failed!');
            }
          });
      }
    });
  };

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <AttachFileIcon sx={{ transform: 'scale(1.3)' }} />
          <DialogToolbarHeading>
            {t('titles.attached_documents')}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogContent sx={{ py: 0, px: 1, width: '100%' }}>
        <Box>
          <ModelTable
            key={`tblid-${records.length}`}
            dc={dcFile}
            records={records}
            allowSelection="many"
            title=""
            allowAdd
            handleAdd={handleAdd}
            allowRowAction={() => false}
            onRowAction={() => {}}
            viewName="info_dialog"
            allowColumnPicker={false}
          >
            <TableButtonDelete onClick={handleDeleteDocs} />
          </ModelTable>
        </Box>
      </DialogContent>
    </DraggableDialog>
  );
};

export default AttachedDocumentDialog;
