import React from "react";

// MUI
import Slider from "@mui/material/Slider";

type IOpacitySlider = {
  checked: boolean | "false" | "mixed" | "true" | undefined;
  value: number;
  handleChange: ((event: Event, value: number | number[], activeThumb: number) => void) | undefined
}

const OpacitySlider = (props: IOpacitySlider) => {
  const { checked, handleChange, value } = props

    return (
        <Slider
          size="medium"
          aria-label="Opacity"
          defaultValue={100}
          value={value}
          valueLabelDisplay="auto"
          min={0}
          max={100}
          disabled={ !checked }
          onChange={handleChange}
          sx={{
            width: 100,
            mr: 3
          }}
        />
    )
}

export default OpacitySlider