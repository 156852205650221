/* eslint-disable */
import React, { FunctionComponent, useState, useRef } from 'react';
import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';

// Types
import ConfirmDialog, {
  IConfirmDialogOptions,
} from '@/ui/Dialog/ConfirmDialog/ConfirmDialog';

export type ConfirmResult = {
  confirmed?: boolean;
  canceled?: boolean;
  error?: boolean;
};

export interface IDialogProvider {}

export type DialogProviderState = {
  component1: FunctionComponent<IDialogProps> | null;
  component2: FunctionComponent<IDialogProps> | null;
  props1: IDialogProps;
  props2: IDialogProps;
};

const DialogProvider: FunctionComponent<IDialogProvider> = (
  props: React.PropsWithChildren<IDialogProvider>
) => {
  const { children } = props;

  const [providerState, setProviderState] = useState<DialogProviderState>({
    component1: null,
    props1: {},
    component2: null,
    props2: {},
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogOptions, setConfirmDialogOptions] =
    useState<IConfirmDialogOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: (r: ConfirmResult) => void;
    reject: (r: ConfirmResult) => void;
  }>();

  const showConfirmDialog = (options: IConfirmDialogOptions) => {
    setConfirmDialogOptions(options);
    setConfirmDialogOpen(true);
    return new Promise<ConfirmResult>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleConfirmClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve({ canceled: true });
      // can also be .reject but then we have to catch this in useFormController
    }

    setConfirmDialogOpen(false);
  };

  const handleConfirmSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve({ confirmed: true });
    }

    setConfirmDialogOpen(false);
  };

  const showDialog = (component: FunctionComponent, props = {}, level = 1) => {
    if (level === 1) {
      setProviderState({
        component1: component,
        props1: {...props, level: 1},
        component2: null,
        props2: {},
      });
    } else if (level === 2) {
      setProviderState((prevState) => ({
        component1: prevState?.component1,
        props1: prevState?.props1,
        component2: component,
        props2: {...props, level: 2},
      }));
    }
  };

  const hideDialog = (level = 1) => {
    if (level === 1) {
      setProviderState({
        component1: null,
        props1: {},
        component2: null,
        props2: {},
      });
    } else if (level === 2) {
      setProviderState((prevState) => ({
        component1: prevState.component1,
        props1: prevState.props1,
        component2: null,
        props2: {},
      }));
    }
  };

  // Add this method to update props of an active dialog
  const updateDialogProps = (level: number, newProps: IDialogProps) => {
    console.log("update dial props", level, newProps);
    
    if (level === 1) {
      setProviderState((prevState) => ({
        ...prevState,
        props1: { ...prevState.props1, ...newProps },
      }));
    } else if (level === 2) {
      setProviderState((prevState) => ({
        ...prevState,
        props2: { ...prevState.props2, ...newProps },
      }));
    }
  };

  const providerValue = {
    component1: providerState?.component1,
    component2: providerState?.component2,
    props1: providerState?.props1,
    props2: providerState?.props2,
    showDialog,
    hideDialog,
    showConfirmDialog,
    updateDialogProps
  };

  return (
    <>
      <DialogContext.Provider value={providerValue}>
        {children}
      </DialogContext.Provider>
      <ConfirmDialog
        open={confirmDialogOpen}
        onSubmit={handleConfirmSubmit}
        onCancel={handleConfirmClose}
        {...confirmDialogOptions}
      />
    </>
  );
};

export default DialogProvider;
