/* eslint-disable */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';

// Types
import { TCustomGFIActionProps } from '@/@types/views/map'

// OL
import { transform as OlTransform } from "ol/proj";

// Custom
import MapCardActionButton from '@/ui/MapCard/MapCardActionButton';
import Permissions from '@/lib/permissions';

// MUI
import PublicIcon from '@mui/icons-material/Public';
import LaunchIcon from '@mui/icons-material/Launch';
import UserContext, { UserContextType } from '@/context/UserContext/UserContext';
import { Box, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';

const Show3DButtonListItem = (props: TCustomGFIActionProps) => {

    const { t } = useTranslation();

    const userContext = useContext(UserContext) as UserContextType;

    const { featureData, handleCloseMenu } = props;

    const handleShow3D = () => {
        const [cx, cy] = OlTransform(
          [featureData.position[0], featureData.position[1]],
          'EPSG:3857',
          'EPSG:4326'
        ).map((x) => (x * Math.PI) / 180);
    
        // todo add z coordinate
        const url = `/3d?cx=${cx}&cy=${cy}&cz=0`;
    
        window.open(url, '_blank');
    };

    const handleClick = () => {
        handleShow3D();
        if (handleCloseMenu) {
            handleCloseMenu();
        }
    }

    return  !userContext.hasAnyPermission([Permissions.ThreeDView]) ? null : (
        <MenuItem
            onClick={handleClick}
            sx={{minHeight: "36px"}}
        >
            <ListItemIcon>
                <PublicIcon color='primary' fontSize='small'/>
            </ListItemIcon>
            <ListItemText>
                {t('buttons.show_3d')}
            </ListItemText>
            <LaunchIcon sx={{ml:1}} color='disabled' fontSize='small'/>
        </MenuItem>
    )
}

export default Show3DButtonListItem;