/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';

// Custom Components
import ModelTable from '@/ui/Table/ModelTable';

// Types
import { DCRecord } from '@/@types/lib/dataController';
import { RowActionFn } from '@/@types/ui/Table';
import { IDataController } from '@/lib/DataController';

type PhotoCentricTableProps = {
  records: Array<DCRecord>;
  onRowSelect: (id: number, record: DCRecord) => void;
  onRowAction?: RowActionFn;
  rowSelectedCustom?: number;

  titleToken: string;
  dc: IDataController;
  viewName?: string;
};

const PhotoCentricTable = (props: PhotoCentricTableProps) => {
  const { t } = useTranslation();
  const { records, onRowSelect, onRowAction, rowSelectedCustom } = props;
  const { titleToken, dc, viewName } = props;

  const handleRowSelect = (
    evt: React.SyntheticEvent,
    sel: { [key: string]: DCRecord }
  ) => {
    const record = sel[Object.keys(sel)[0]];
    const id = record.id as number;

    if (record !== undefined) {
      onRowSelect(id, record);
    }
  };

  return (
    <ModelTable
      title={t(titleToken)}
      viewName={viewName || 'processing'}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter
      allowExport={false}
      allowAdd={false}
      allowColumnPicker
      // allowEdit={false}
      // disableControls
      onRowAction={onRowAction !== undefined ? onRowAction : (id) => {}}
      onRowClick={handleRowSelect}
      // initialPageSize={25}
      allowRowAction={() => true}
      // trackTableState={false}
      // unselectRow
      rowSelectedCustom={rowSelectedCustom}
    />
  );
};

export default PhotoCentricTable;
