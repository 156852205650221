import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

// MUI Icons
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

// Custom Components
import TableHeaderButton from './TableHeaderButton';
import { IMenuState } from '@/@types/ui/Table';

const TableHeaderButtonColumnPicker = (props: any) => {
  const { t } = useTranslation();
  const { columns, smallButtons } = props;

  const [menuState, setMenuState] = React.useState<IMenuState>({
    open: false,
    anchorEl: null,
  });

  const handleOpen = (evt: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  };

  const handleClose = () => {
    setMenuState({ open: false, anchorEl: null });
  };

  return (
    <>
      <TableHeaderButton
        startIcon={<ViewColumnIcon />}
        variant="outlined"
        onClick={handleOpen}
        smallButtons={smallButtons}
      >
        {t('buttons.toggle_columns')}
      </TableHeaderButton>
      <Menu
        open={menuState.open}
        onClose={handleClose}
        anchorEl={menuState.anchorEl}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {columns.filter((col: any) => col.columnDef.header !== "").map((c: any, i: number) => (
          <MenuItem
            value={i}
            key={c.columnDef.header}
            onClick={c.getToggleVisibilityHandler()}
          >
            <Switch
              color="primary"
              checked={c.getIsVisible()}
              value={c.getIsVisible()}
              onChange={c.getToggleVisibilityHandler()}
            />
            <Typography
              variant="caption"
              style={{ textTransform: 'uppercase', fontWeight: 600 }}
            >
              {c.columnDef.header as string}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TableHeaderButtonColumnPicker;
