/* eslint-disable */
import React, { ChangeEvent, ChangeEventHandler } from 'react';
import numbro from 'numbro';

// Material UI
import Slider from '@mui/material/Slider';
import { Box } from '@mui/material';

// Types
import {
  INumberRangeFilter,
  NumberRangeFilterWrapperFn,
  FilterFn,
} from '@/@types/ui/Table';
import { DCRecord } from '@/@types/lib/dataController';

/**
 * Filter wrapper function that returns the NumberRangeFilter component
 *
 * @returns The NumberRangeFilter component
 */
const NumberRangeFilter: NumberRangeFilterWrapperFn = () => {
  const NumberSlider: FilterFn<INumberRangeFilter> = (filter) => {
    const { column } = filter;
    const { id, filterValue, setFilter, preFilteredRows } = column;
    const defaultValue: [number, number] = [-1, -1];

    const rowsWithValueCount = preFilteredRows?.filter((row: DCRecord) =>
      row?.values ? (row?.values as DCRecord)[id] : row[id]
    ).length;

    preFilteredRows?.forEach((row: any) => {
      if (row?.values && row?.values[id]) {
        const unformatedvalue = row.values[id].props?.unformatedvalue
          ? row.values[id].props.unformatedvalue
          : row.values[id];
        if (defaultValue[0] === -1 && defaultValue[1] === -1) {
          defaultValue.splice(0, 1, unformatedvalue);
          defaultValue.splice(1, 1, unformatedvalue);
        } else if (unformatedvalue < defaultValue[0]) {
          defaultValue.splice(0, 1, unformatedvalue);
        } else if (unformatedvalue > defaultValue[1]) {
          defaultValue.splice(1, 1, unformatedvalue);
        }
      } else if (row[id]) {
        const unformatedvalue = row[id];
        if (defaultValue[0] === -1 && defaultValue[1] === -1) {
          // defaultValue.splice(0, 1, unformatedvalue);
          defaultValue[0] = unformatedvalue;
          // defaultValue.splice(1, 1, unformatedvalue);
          defaultValue[1] = unformatedvalue;
        } else if (unformatedvalue < defaultValue[0]) {
          // defaultValue.splice(0, 1, unformatedvalue);
          defaultValue[0] = unformatedvalue;
        } else if (unformatedvalue > defaultValue[1]) {
          // defaultValue.splice(1, 1, unformatedvalue);
          defaultValue[1] = unformatedvalue;
        }
      }
    });

    const minMaxDiff = defaultValue[1] - defaultValue[0];

    const step =
      minMaxDiff < 10
        ? 0.1
        : minMaxDiff < 100
        ? 1
        : minMaxDiff < 1000
        ? 10
        : 100;

    if (!filterValue && !(defaultValue[0] === -1 && defaultValue[1] === -1)) {
      setFilter([...defaultValue]);
    }

    // if(filterValue && (filterValue as [number,number])[0] === -1 && (filterValue as [number,number])[1] === -1){
    //   setFilter([...defaultValue]);
    // }

    const handleSliderValue = (evt: Event, newValue: number | number[]) => {
      setFilter(newValue as [number, number]);
    };

    const handleChangeTextField: ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const combinedValue: [number, number] = filterValue as [number, number];
      const index = e.target.id === 'outlined-number-1' ? 0 : 1;
      combinedValue[index] = parseFloat(e.target.value);
    };

    return filterValue ? (
      <Box>
        <Slider
          value={filterValue || defaultValue}
          onChange={handleSliderValue}
          // onChangeCommitted={handleChange}
          min={defaultValue[0]}
          max={defaultValue[1]}
          valueLabelDisplay="auto"
          color="primary"
          valueLabelFormat={(x) =>
            numbro(x).format({ thousandSeparated: true, mantissa: 2 })
          }
          // marks={true}
          step={step}
          disabled={rowsWithValueCount === 0}
        />
        {/* this is Vlatko's idea, we'll see if we will keep it */}
        {/* right now it messes with the positioning of other filters */}
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <TextField
            id="outlined-number-1"
            label="min"
            type="number"
            key={0}
            value={(filterValue as [number, number])[0]}
            onChange={handleChangeTextField}
            size="small"
            margin="dense"
          />
          <Box sx={{ width: '5%' }}>
            <hr />
          </Box>
          <TextField
            id="outlined-number-2"
            label="max"
            type="number"
            key={1}
            value={(filterValue as [number, number])[1]}
            onChange={(e) => handleChangeTextField(e)}
            size="small"
            margin="dense"
          />
        </Box> */}
      </Box>
    ) : null;
  };
  return NumberSlider;
};

export default NumberRangeFilter;
