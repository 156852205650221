/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react';
import MapContext from '@/context/MapContext/MapContext';
import { MapContextType } from '@/context/MapContext/MapContext';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import { getCenter } from 'ol/extent';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import { LineString, Polygon } from 'ol/geom';
import OlSourceVector from "ol/source/Vector";
import { useTheme } from "@mui/material";


type HighlightAnimationProps = {
  features: any, // Feature<Geometry>[] | Point[] | VectorSource<Geometry>, // Features | Points | Points
  setSource: (source: any) => void
}


export const HighlightAnimation = (props: HighlightAnimationProps) => {
  const { features, setSource } = props;
  const mapContext = useContext(MapContext) as MapContextType;
  const theme = useTheme();

  const [ vectorSource, setVectorSource ] = useState<VectorSource<any>>(new VectorSource);
  const layerLoaded = useRef<boolean>(false);

  const currentAnimationFeatures = useRef<Array<any>>([]);

  const hex2rgb = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    
    return { r, g, b };
  }
  const animationColor = hex2rgb(theme.palette.primary.main);

  const pointStyle = new Style({
    stroke: new Stroke({
    color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`, 
    width: 2,
    }),
    fill: new Fill({
    color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 0.2)`,
    }),
  });

  useEffect(() => {
    if (mapContext && mapContext !== null && mapContext.map !== null && layerLoaded.current === false) {
      // @ts-ignore
      mapContext.map.addLayer(new VectorLayer({
        className: 'ol-layer-animation-expanding',
        source: vectorSource,
        zIndex: 899
      }));
      layerLoaded.current = true;
    }
  }, [mapContext]);

  const animatePolygon = (feature: any, direction: number, color: number, maxColor: number, originalStyle: Style, scale: number = 1, originalCoordinates: Array<Array<Array<number>>> = feature.getGeometry().getCoordinates()) => {
    if (direction === 1) {
      color += maxColor / 75;
      scale += 0.0115;  
    } else if (direction === 2) {
      color -= maxColor / 125;
      if (scale > 1) {
        scale -= 0.0085;
      } else {
        scale = 1; 
      }
    }
  
    const newStyle = new Style({
      stroke: new Stroke({
        color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`,
        width: 4
      }),
      fill: new Fill({
        // Change the color over the course of the animation
        color: `rgba(${String(256 - color * (256 - animationColor.r))}, ${String(256 - color * (256 - animationColor.g))}, ${String(256 - color * (256 - animationColor.b))}, ${String(color)})`
      }),
    });
    feature.setStyle(newStyle);
  
    // Scale polygon geometry
    const geometry = feature.getGeometry();
    if (geometry instanceof Polygon) {
      const coordinates = originalCoordinates[0];
      const center = getCenter(geometry.getExtent());
      const scaledCoordinates = coordinates.map(coord => {
        return [
          center[0] + (coord[0] - center[0]) * scale,
          center[1] + (coord[1] - center[1]) * scale
        ];
      });
      geometry.setCoordinates([scaledCoordinates]);
    }
  
    // Recursive calls to the animation API
    if (direction === 1 && color <= maxColor) {
      requestAnimationFrame(() => animatePolygon(feature, 1, color, maxColor, originalStyle, scale, originalCoordinates));
    } else if (direction === 2 && scale > 1) {
      requestAnimationFrame(() => animatePolygon(feature, 2, color, maxColor, originalStyle, scale, originalCoordinates));
    } else if (scale > 1) {
      requestAnimationFrame(() => animatePolygon(feature, 2, color, maxColor, originalStyle, scale, originalCoordinates));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        geometry.setCoordinates(originalCoordinates); // Ensure the geometry is set to original coordinates
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter(f => f !== feature.id_);
        setSource(new OlSourceVector({}));
      }, 300);
    }
  }
  
  const animateLineString = (feature: any, direction: number, width: number, maxWidth: number, originalStyle: Style) => {
    if(direction === 1) {
      width += maxWidth/50;
    } else {
      width -= maxWidth/75;
    }
    
    const newStyle = new Style({
      stroke: new Stroke({
        color: `rgba(${animationColor.r}, ${animationColor.g}, ${animationColor.b}, 1)`,
        lineJoin: 'miter',
        width: width
      })
    });
    feature.setStyle(newStyle);

    // Recursive calls to the animation API
    if (direction === 1 && width <= maxWidth) {
      requestAnimationFrame(() => animateLineString(feature, 1, width, maxWidth, originalStyle));
    } else if (width >= 4) {
      requestAnimationFrame(() => animateLineString(feature, 2, width, maxWidth, originalStyle));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter(f => f !== feature.id_);
        setSource(new OlSourceVector({}));
      }, 300);
    }
  }

  const animatePoint = (feature: any, direction: number, radius: number, maxRadius: number, originalStyle: Style, prevCircleFeature?: any) => {
    if(direction === 1) {
      radius += maxRadius/50;
    } else {
      radius -= maxRadius/75;
    }
    
    // @ts-ignore
    const circleGeometry = new Circle(feature && feature.getGeometry() && feature.getGeometry()?.getCoordinates() ? feature.getGeometry().getCoordinates() : [1], radius);
    const circleFeature = new Feature({ id: 1, geometry: circleGeometry });

    if(prevCircleFeature) {
      vectorSource.removeFeature(prevCircleFeature);
    }
    
    circleFeature.setStyle(pointStyle);
    vectorSource.addFeature(circleFeature);

    // Recursive calls to the animation API
    if (direction === 1 && radius <= maxRadius) {
      requestAnimationFrame(() => animatePoint(feature, 1, radius, maxRadius, originalStyle, circleFeature));
    } else if (radius >= 0.55) {
      requestAnimationFrame(() => animatePoint(feature, 2, radius, maxRadius, originalStyle, circleFeature));
    } else {
      setTimeout(() => {
        feature.setStyle(originalStyle);
        currentAnimationFeatures.current = currentAnimationFeatures.current.filter(f => f !== feature.id_);
        vectorSource.removeFeature(circleFeature);
        setSource(new OlSourceVector({}));
      }, 100);
    }
  }

  useEffect(() => {
    if (layerLoaded.current && features && features.length > 0) {
      vectorSource.clear();
      const timeoutLength = mapContext.map?.getView().getAnimating() === true ? 1000 : 0;

      setTimeout(() => {
        for(const feature of features) {
          if(currentAnimationFeatures.current.indexOf(feature.id_) === -1) {
            const originalStyle = feature.getStyle();
  
            if(feature.getGeometry() instanceof Polygon) {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, feature.id_];
              requestAnimationFrame(() => animatePolygon(feature, 1, 0.5, 0.9, originalStyle));
            }
            if(feature.getGeometry() instanceof LineString) {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, feature.id_];
              requestAnimationFrame(() => animateLineString(feature, 1, 4, 36, originalStyle));
            }
            if(feature.getGeometry() instanceof Point) {
              currentAnimationFeatures.current = [...currentAnimationFeatures.current, feature.id_];
              requestAnimationFrame(() => animatePoint(feature, 1, 5, 18, originalStyle));
            }
          }
        }
      }, timeoutLength);       
    }
  }, [features]);

  return null;
}