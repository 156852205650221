import { IModel } from '@/@types/models/model';
import buttonFields from '@/models/helpers/buttonFields';
import commonFields from './helpers/commonFields';

const Objekti: IModel = {
  title: 'Objekti',
  source: 'objekti',
  apiPath: 'ena/objekti',
  forms: {
    // default: {
    //   fields: [
    //     'obv',
    //     'adrobv',
    //     'sifobv',
    //     'nasobv',
    //     'adrobj',
    //     'nasobj',
    //     'ulobj',
    //     'kbobj',
    //     'lokacija_id',
    //     'zap',
    //   ],
    // },
  },
  listViews: {
    default: {
      fields: [
        'obj_id',
        'kat',
        'stambeno',
        'poslovno',
        'garaza',
        'pomocno',
        'balk_ter',
        'z_func_o',
        'neizgr_gr',
        'ostalo',
        'ukupno',
      ],
      hidden: ['id'],
    },
    tooltip: {
      fields: [
        'obj_id',
        // { source: 'kat', tooltip: 'objekti.kat_uppercase' },
        // 'kat',
        // 'stambeno',
        // 'poslovno',
        // 'garaza',
        // 'pomocno',
        // 'balk_ter',
        // 'z_func_o',
        // 'neizgr_gr',
        // 'ostalo',
        // 'ukupno',
      ],
    },
    attribute_table: {
      fields: [
        'id',
        'obj_id',
        'adresa_full',
        'kn_zona',
        'kn_koef',
        'ko',
        'kc',
        // 'pre_pt',
        'broj_katova',
        'deselect',
        'btn_show_object',
        'btn_show_photos',
      ],
      hidden: ['id'],
    },
    attribute_table_with_3d: {
      fields: [
        'id',
        'obj_id',
        'adresa_full',
        'kn_zona',
        'kn_koef',
        'ko',
        'kc',
        // 'pre_pt',
        'broj_katova',
        'deselect',
        'btn_show_3d',
        'btn_show_object',
        'btn_show_photos',
      ],
      hidden: ['id'],
    },
    processing: {
      fields: [
        'id',
        'obj_id',
        'adresa_full',
        'kn_zona',
        'kn_koef',
        'ko',
        'kc',
        // 'pre_pt',
        'broj_katova',
        'btn_show_object',
      ],
      hidden: ['id'],
    },
    processing_with_3d: {
      fields: [
        'id',
        'obj_id',
        'adresa_full',
        'kn_zona',
        'kn_koef',
        'ko',
        'kc',
        // 'pre_pt',
        'broj_katova',
        'btn_show_object',
        'btn_show_3d',
      ],
      hidden: ['id'],
    },
  },
  fields: [
    {
      title: 'id',
      source: 'id',
      sort: false,
      ttoken: 'common.id',
      tooltip: 'ena:objekti.id',
      type: 'numeric',
      idattr: true,
      readonly: true,
      filter: true,
    },
    {
      title: 'id',
      source: 'obj_id',
      sort: false,
      ttoken: 'ena:objekti.id',
      tooltip: 'objekti.id',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Kat',
      source: 'kat',
      sort: false,
      ttoken: 'ena:objekti.kat',
      tooltip: 'objekti.kat',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Stambeno',
      source: 'stambeno',
      sort: false,
      ttoken: 'ena:objekti.stambeno',
      tooltip: 'objekti.stambeno',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Poslovno',
      source: 'poslovno',
      sort: false,
      ttoken: 'ena:objekti.poslovno',
      tooltip: 'objekti.poslovno',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Garaza',
      source: 'garaza',
      sort: false,
      ttoken: 'ena:objekti.garaza',
      tooltip: 'objekti.garaza',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Pomocno',
      source: 'pomocno',
      sort: false,
      ttoken: 'ena:objekti.pomocno',
      tooltip: 'objekti.pomocno',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Balk_ter',
      source: 'balk_ter',
      sort: false,
      ttoken: 'ena:objekti.balk_ter',
      tooltip: 'objekti.balk_ter',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Z_func_o',
      source: 'z_func_o',
      sort: false,
      ttoken: 'ena:objekti.z_func_o',
      tooltip: 'objekti.z_func_o',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Neizgr_gr',
      source: 'neizgr_gr',
      sort: false,
      ttoken: 'ena:objekti.neizgr_gr',
      tooltip: 'objekti.neizgr_gr',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Ostalo',
      source: 'ostalo',
      sort: false,
      ttoken: 'ena:objekti.ostalo',
      tooltip: 'objekti.ostalo',
      type: 'numeric',
      filter: true,
    },
    {
      title: 'Ukupno',
      source: 'ukupno',
      sort: false,
      ttoken: 'ena:objekti.ukupno',
      tooltip: 'objekti.ukupno',
      type: 'numeric',
      filter: true,
    },
    // {
    //   title: 'Fishnet ID',
    //   source: 'fishnet_id',
    //   sort: false,
    //   ttoken: 'ena:objekti.fishnet',
    //   tooltip: 'objekti.fishnet',
    //   type: 'numeric',
    //   readonly: true,
    // },
    // {
    //   title: 'Projekt ID',
    //   source: 'projekt_id',
    //   sort: false,
    //   ttoken: 'ena:objekti.projekt',
    //   tooltip: 'objekti.projekt',
    //   type: 'numeric',
    //   readonly: true,
    // },
    buttonFields.show_3d,
    buttonFields.show_object,
    buttonFields.show_photos,
    {
      title: 'Naselje',
      source: 'naselje',
      ttoken: 'ena:objekti.naselje',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'Ulica',
      source: 'ulica',
      ttoken: 'ena:objekti.ulica',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'kb',
      source: 'kb',
      ttoken: 'ena:objekti.kb',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'adresa_full',
      source: 'adresa_full',
      ttoken: 'ena:objekti.adresa_objekta',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'kn zona',
      source: 'kn_zona',
      ttoken: 'ena:objekti.kn_zona',
      type: 'text',
      readonly: true,
      filter: true,
      // align: 'center',
    },
    // {
    //   title: 'PRE PT',
    //   source: 'pre_pt',
    //   ttoken: 'ena:objekti.pre_pt',
    //   type: 'numeric',
    //   readonly: true,
    // },
    {
      title: 'KN Koef',
      source: 'kn_koef',
      ttoken: 'ena:objekti.kn_koef',
      type: 'numeric',
      readonly: true,
      filter: true,
      // align: 'center',
    },
    {
      title: 'Lokacija ID',
      source: 'lokacija_id',
      ttoken: 'ena:objekti.lokacija_id',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'Katastarska opcina',
      source: 'ko',
      ttoken: 'ena:komunalni_obveznici.ko',
      type: 'text',
      sort: true,
      filter: true,
    },
    {
      title: 'Katastarska cestica',
      source: 'kc',
      ttoken: 'ena:komunalni_obveznici.kc',
      type: 'text',
      sort: true,
      filter: true,
    },
    {
      title: 'Broj katova',
      source: 'broj_katova',
      ttoken: 'ena:objekti.broj_katova',
      type: 'text',
      sort: true,
      // align: 'center',
      filter: true,
    },
    {
      title: 'Broj slika',
      source: 'broj_slika',
      ttoken: 'ena:objekti.broj_slika',
      type: 'numeric',
      sort: true,
      filter: true,
    },
    {
      title: 'cx',
      source: 'cx',
      ttoken: '',
      type: 'numeric',
      sort: true,
      filter: true,
    },
    {
      title: 'cy',
      source: 'cy',
      ttoken: '',
      type: 'numeric',
      sort: true,
      filter: true,
    },
    {
      title: 'cz',
      source: 'cz',
      ttoken: '',
      type: 'numeric',
      sort: true,
      filter: true,
    },
    commonFields.wkt,
    buttonFields.deselect,
  ],
};

export default Objekti;
