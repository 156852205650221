/* eslint-disable */
import React, { ReactNode } from 'react'

import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DraggableDialog from '@/ui/Dialog/DraggableDialog'
import DynamicDialog from '@/ui/Dialog/DynamicDialog';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import { Box, DialogContent, Toolbar, useMediaQuery, useTheme } from '@mui/material'

import { GSInfoCardProps } from '../GSInfoCard';
import { useTranslation } from 'react-i18next';
import DialogBody from '@/ui/Dialog/DialogBody';
import { IDialogProps } from '@/context/DialogContext/DialogContext';


const GSInfoDialog = (props: IDialogProps) => {

    const { t } = useTranslation();

    const { handleClose, body, identifier, FormContent } = props;
    
    return (
        <DraggableDialog PaperProps={{
           sx: {
            margin: {
                xs: "32px 0px",
                md: "32px"
            },
            maxWidth: {
                xs: "100%",
                md: "900px"
            },
            width: {
                xs: "100%",
                md: "calc(100% - 64px)"
            },
           }
        }} maxWidth='md' open={true} >
            <DialogHeader>
                <Toolbar variant="dense" disableGutters={true}>
                <DialogToolbarHeading>{t('map:titles.gsinfocard.'+identifier)}</DialogToolbarHeading>
                <ToolbarFillContent />
                <DialogToolbarButtonClose onClick={handleClose} />
                </Toolbar>
            </DialogHeader>
            <DialogContent sx={{p:0, width: "100%"}}>
                <Box sx={{mx:{xs: 1, md: 2}, mt:{xs:1,md:2}}}>
                    <FormContent/>
                </Box>
                {body}
            </DialogContent>
        </DraggableDialog>
    )
}

export default GSInfoDialog;